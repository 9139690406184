<template>
  <div class="container">
    <div class="row">

      <div class="col-lg-9 col-md-8" style="margin-top:20px;">
        <div v-if="!loaded">در حال بارگذاری...</div>
        <div v-else>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <h3>فروش کل در طول زمان</h3>
              <div class="chart-container">
                <LineChart :data="totalSalesOverTimeData" :options="chartOptions" v-if="totalSalesOverTimeData"/>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <h3>تعداد تراکنش‌ها در طول زمان</h3>
              <div class="chart-container">
                <LineChart :data="numberOfTransactionsOverTimeData" :options="chartOptions" v-if="numberOfTransactionsOverTimeData"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <h3>فروش بر اساس محصول</h3>
              <div class="chart-container">
                <BarChart :data="salesByProductData" :options="chartOptions" v-if="salesByProductData"/>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <h3>فروش مشاوره در مقابل فروش غیر مشاوره</h3>
              <div class="chart-container">
                <PieChart :data="mentorshipVsNonMentorshipSalesData" :options="chartOptions" v-if="mentorshipVsNonMentorshipSalesData"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <h3>شارژ کیف پول در مقابل روش‌های پرداخت دیگر</h3>
              <div class="chart-container">
                <PieChart :data="walletChargeVsOtherPaymentMethodsData" :options="chartOptions" v-if="walletChargeVsOtherPaymentMethodsData"/>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <h3>فروش داخلی در مقابل فروش خارجی</h3>
              <div class="chart-container">
                <PieChart :data="domesticVsAbroadSalesData" :options="chartOptions" v-if="domesticVsAbroadSalesData"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <h3>بهترین مشتریان بر اساس کل مبلغ خرج شده</h3>
              <div class="chart-container">
                <BarChart :data="topCustomersByTotalSpentData" :options="chartOptions" v-if="topCustomersByTotalSpentData"/>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <h3>حجم تراکنش بر اساس روز هفته</h3>
              <div class="chart-container">
                <BarChart :data="transactionVolumeByDayOfWeekData" :options="chartOptions" v-if="transactionVolumeByDayOfWeekData"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <h3>میانگین ارزش تراکنش در طول زمان</h3>
              <div class="chart-container">
                <LineChart :data="averageTransactionValueOverTimeData" :options="chartOptions" v-if="averageTransactionValueOverTimeData"/>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <h3>تعداد تراکنش‌ها بر اساس مقدار</h3>
              <div class="chart-container">
                <BarChart :data="frequencyOfTransactionAmountsData" :options="chartOptions" v-if="frequencyOfTransactionAmountsData"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-12" style="margin-top:20px;">
        <div class="card mt-4 mt-md-0">
          <div class="card-body">
            <h5 class="card-title">فیلترها</h5>
            <div class="mt-4">
              <label for="productFilter">فیلتر بر اساس محصول:</label>
              <div id="productFilter">
                <div v-for="product in products" :key="product.id" class="form-check">
                  <input type="checkbox" class="form-check-input" :id="'product-' + product.id" :value="product.id" v-model="selectedProductIDs" @change="fetchChartData">
                  <label class="form-check-label" :for="'product-' + product.id">{{ product.title }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { Line, Bar, Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, BarElement, PointElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, BarElement, PointElement, CategoryScale, LinearScale, ArcElement);

export default {
  name: 'AnalyticalCharts',
  components: {
    LineChart: Line,
    BarChart: Bar,
    PieChart: Pie
  },
  data() {
    return {
      loaded: false,
      totalSalesOverTimeData: null,
      numberOfTransactionsOverTimeData: null,
      salesByProductData: null,
      mentorshipVsNonMentorshipSalesData: null,
      walletChargeVsOtherPaymentMethodsData: null,
      domesticVsAbroadSalesData: null,
      topCustomersByTotalSpentData: null,
      transactionVolumeByDayOfWeekData: null,
      averageTransactionValueOverTimeData: null,
      frequencyOfTransactionAmountsData: null,
      products: [],
      selectedProductIDs: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    this.fetchProducts();
    this.fetchChartData();
  },
  methods: {
    fetchProducts() {
      const token = localStorage.getItem('jwt');
      axios.get('https://dashboard.ordmenpodcast.com/v/list_webinars.php', {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        this.products = response.data.webinars;
      })
      .catch(error => {
        console.error(error);
      });
    },
    fetchChartData() {
      const token = localStorage.getItem('jwt');
      const productIDs = this.selectedProductIDs.join(',');
      const endpoints = [
        'totalSalesOverTime',
        'numberOfTransactionsOverTime',
        'salesByProduct',
        'mentorshipVsNonMentorshipSales',
        'walletChargeVsOtherPaymentMethods',
        'domesticVsAbroadSales',
        'topCustomersByTotalSpent',
        'transactionVolumeByDayOfWeek',
        'averageTransactionValueOverTime',
        'frequencyOfTransactionAmounts'
      ];

      const requests = endpoints.map(endpoint => 
        axios.get(`https://dashboard.ordmenpodcast.com/v/chart.php?chartType=${endpoint}&productIDs=${productIDs}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      );

      Promise.all(requests)
        .then(responses => {
          this.totalSalesOverTimeData = this.formatLineChartData(responses[0].data.data, 'total_sales', 'date', 'فروش کل');
          this.numberOfTransactionsOverTimeData = this.formatLineChartData(responses[1].data.data, 'transaction_count', 'date', 'تعداد تراکنش‌ها');
          this.salesByProductData = this.formatBarChartData(responses[2].data.data, 'total_sales', 'product_id', 'فروش کل');
          this.mentorshipVsNonMentorshipSalesData = this.formatPieChartData(responses[3].data.data, 'total_sales', 'label', 'فروش کل');
          this.walletChargeVsOtherPaymentMethodsData = this.formatPieChartData(responses[4].data.data, 'total_sales', 'label', 'فروش کل');
          this.domesticVsAbroadSalesData = this.formatPieChartData(responses[5].data.data, 'total_sales', 'label', 'فروش کل');
          this.topCustomersByTotalSpentData = this.formatBarChartData(responses[6].data.data, 'total_spent', 'name', 'کل مبلغ خرج شده');
          this.transactionVolumeByDayOfWeekData = this.formatBarChartData(this.translateDayOfWeekLabels(responses[7].data.data), 'transaction_count', 'day_of_week', 'تعداد تراکنش‌ها');
          this.averageTransactionValueOverTimeData = this.formatLineChartData(responses[8].data.data, 'avg_transaction_value', 'date', 'میانگین ارزش تراکنش');
          this.frequencyOfTransactionAmountsData = this.formatBarChartData(responses[9].data.data, 'frequency', 'amount', 'تعداد تراکنش‌ها');

          // Translate specific labels
          this.translateLabels();
          this.loaded = true;
        })
        .catch(error => {
          console.error(error);
          this.loaded = true;
        });
    },
    formatLineChartData(data, valueKey, labelKey, labelName) {
  return {
    labels: data.map(item => item[labelKey]),
    datasets: [
      {
        label: labelName,
        data: data.map(item => item[valueKey]),
        fill: false,
        borderColor: 'rgba(75, 192, 192, 1)'
      }
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            maxRotation: 90,
            minRotation: 45
          }
        }
      }
    }
  };
},
    formatBarChartData(data, valueKey, labelKey, labelName) {
      return {
        labels: data.map(item => item[labelKey]),
        datasets: [
          {
            label: labelName,
            data: data.map(item => item[valueKey]),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }
        ]
      };
    },
    formatPieChartData(data, valueKey, labelKey, labelName) {
      return {
        labels: data.map(item => item[labelKey]),
        datasets: [
          {
            label: labelName,
            data: data.map(item => item[valueKey]),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
          }
        ]
      };
    },
    translateLabels() {
      // Translating specific labels for Pie charts
      this.mentorshipVsNonMentorshipSalesData.labels = this.mentorshipVsNonMentorshipSalesData.labels.map(label => {
        if (label === 'Mentorship') return 'مشاوره';
        if (label === 'Non-Mentorship') return 'غیر مشاوره';
        return label;
      });
      this.walletChargeVsOtherPaymentMethodsData.labels = this.walletChargeVsOtherPaymentMethodsData.labels.map(label => {
        if (label === 'Wallet Charge') return 'شارژ کیف پول';
        if (label === 'Other Methods') return 'روش‌های دیگر';
        return label;
      });
      this.domesticVsAbroadSalesData.labels = this.domesticVsAbroadSalesData.labels.map(label => {
        if (label === 'Abroad') return 'خارجی';
        if (label === 'Domestic') return 'داخلی';
        return label;
      });
    },
    translateDayOfWeekLabels(data) {
      const dayMapping = {
        1: 'یکشنبه',
        2: 'دوشنبه',
        3: 'سه‌شنبه',
        4: 'چهارشنبه',
        5: 'پنج‌شنبه',
        6: 'جمعه',
        7: 'شنبه'
      };
      return data.map(item => ({
        ...item,
        day_of_week: dayMapping[item.day_of_week]
      }));
    }
  }
};
</script>

<style scoped>


.container{
    width: 100% !important;
    max-width: 100% !important;
    font-size:12.5px!important;
  }

.row {
  display: flex;
  flex-wrap: wrap;
}
.col-lg-6, .col-md-12 {
  padding: 10px;
}
.chart-container {
  position: relative;
  height: 400px; /* Fixed height for charts */
}
h3 {
  text-align: center;
}
</style>
