<template>
  <div class="container">
    <div class="row">
      <!-- Left side - Selection of Webinars and Products -->
      <div class="col-lg-6">
        <h2>انتخاب گروه</h2>
        <div class="d-flex">
          <div class="w-50 p-2">
            <label>انتخاب وبینار</label>
            <multiselect
              v-model="selectedWebinars"
              :options="filteredWebinars"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="انتخاب وبینار"
              track-by="id"
              :searchable="true"
              :custom-label="webinarLabel"
              @input="fetchAttendees"
            >
              <template #selection="{ values, isOpen }">
                <span v-if="values.length && !isOpen" class="multiselect__single">
                  {{ values.length }} وبینار انتخاب شده
                </span>
              </template>
            </multiselect>
          </div>
          <div class="w-50 p-2">
            <label>انتخاب محصول</label>
            <multiselect
              v-model="selectedProducts"
              :options="filteredProducts"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="انتخاب محصول"
              label="title"
              track-by="id"
              :searchable="true"
              @input="fetchAttendees"
            >
              <template #selection="{ values, isOpen }">
                <span v-if="values.length && !isOpen" class="multiselect__single">
                  {{ values.length }} محصول انتخاب شده
                </span>
              </template>
            </multiselect>
          </div>
        </div>

        <!-- Members Table -->
        <h3>لیست اعضا</h3>
        
        <!-- Search Bar for Members Table -->
        <input
          type="text"
          v-model="memberSearch"
          class="form-control mb-2"
          placeholder="جستجوی اعضا"
        />

        <div class="table-container">
          <table class="table table-striped">
            <thead>
              <tr>
                <th><input type="checkbox" @change="toggleSelectAll($event)" /></th>
                <th>#</th>
                <th>نام</th>
                <th>ایمیل</th>
                <th>منبع</th>
                <th>اطلاعات بیشتر</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in filteredMembersBySearch" :key="member.email">
                <td><input type="checkbox" :checked="isSelected(member)" @change="toggleMemberSelection(member)" /></td>
                <td>{{ index + 1 }}</td>
                <td>{{ member.name }}</td>
                <td>{{ member.email }}</td>
                <td>{{ member.source }}</td>
                <td>
                  <button class="btn btn-sm btn-info" @click="showMoreInfo(member)">
                    بیشتر
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br><br>
        <!-- Final Table -->
        <h3>اعضای نهایی</h3>
        <table class="table">
          <thead>
            <tr>
              <th>ردیف</th>
              <th>نام</th>
              <th>ایمیل</th>
              <th>منبع</th>
              <th>عملیات</th>
              <th><button class="btn btn-danger btn-sm mb-2" style="margin-bottom:0!important" @click="removeAllFromFinalTable">حذف همه</button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in finalMembers" :key="member.email">
              <td>{{ index + 1 }}</td>
              <td>{{ member.name }}</td>
              <td>{{ member.email }}</td>
              <td>{{ member.source }}</td>
              <td>{{ member.emailStatus || '' }}</td>
              <td>
                <button class="btn btn-danger btn-sm" @click="removeFromFinalTable(index)">
                  حذف
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <br><br><br><br><br>
      </div>

      <!-- Right side - CKEditor and Final Table -->
      <div class="col-lg-6">
        
        <h2>ارسال پیام</h2>
        <!-- Subject Input Field -->
        <input type="text" v-model="emailSubject" class="form-control mb-3" placeholder="موضوع ایمیل" />
        <!-- CKEditor -->
        <ckeditor :editor="editor" v-model="messageContent" :config="editorConfig"></ckeditor>
        <!-- Send Buttons -->
        <div class="d-flex justify-content-between mt-3">
          <button class="btn btn-primary" @click="sendMessages">ارسال اتوماتیک</button>
          <div>
            <button class="btn btn-primary" style="margin-left:5px;" @click="sendTelegramMessages">ارسال پیام تلگرام</button>
            <button class="btn btn-primary" @click="sendEmails">ارسال ایمیل</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for More Information -->
    <div class="modal fade" id="moreInfoModal" tabindex="-1" role="dialog" aria-labelledby="moreInfoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="moreInfoModalLabel">اطلاعات بیشتر</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h5>بقیه اطلاعات</h5>
            <table class="table table-striped">
              <tbody>
                <tr><th>شغل فعلی</th><td>{{ selectedMember.CurrentJob }}</td></tr>
                <tr><th>رشته تحصیلی</th><td>{{ selectedMember.StudyField }}</td></tr>
                <tr><th>مقطع تحصیلی</th><td>{{ selectedMember.EducationLevel }}</td></tr>
                <tr><th>استان/کشور محل سکونت</th><td>{{ selectedMember.CountryStateResidence }}</td></tr>
              </tbody>
            </table>
            <hr>
            <h5>تراکنش‌ها</h5>
            <div v-if="userTransactions.length > 0" class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr><th>نام محصول</th><th>تاریخ</th></tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in userTransactions" :key="transaction.id">
                    <td>{{ transaction.product_name }}</td>
                    <td>{{ splitCreatedAt(transaction.created_at, 1) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p>تراکنشی یافت نشد</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Multiselect from 'vue-multiselect';
import { Modal } from 'bootstrap';
import { htmlToText } from 'html-to-text';

export default {
  components: { Multiselect },
  data() {
    return {
      webinars: [],
      products: [],
      members: [],
      selectedWebinars: [],
      selectedProducts: [],
      finalMembers: [],
      memberSearch: '', // Search query for members table
      messageContent: '',
      emailSubject: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'ar',
        contentsLangDirection: 'rtl',
        alignment: {
          options: ['right', 'left']
        }
      },
      selectedMember: {}, // To store selected member details
      userTransactions: [], // To store transactions of a user
    };
  },
  computed: {
    filteredWebinars() {
      return this.webinars;
    },
    filteredProducts() {
      return this.products;
    },
    filteredMembers() {
      if (this.selectedWebinars.length === 0 && this.selectedProducts.length === 0) {
        return this.members;
      }

      const attendeesEmails = new Set();

      this.selectedWebinars.forEach(webinar => {
        webinar.attendants.split(', ').forEach(email => attendeesEmails.add(email));
      });

      this.selectedProducts.forEach(product => {
        product.attendants.split(', ').forEach(email => attendeesEmails.add(email));
      });

      return this.members.filter(member => attendeesEmails.has(member.email));
    },
    filteredMembersBySearch() {
      if (!this.memberSearch || !this.memberSearch.trim()) {
        return this.filteredMembers;
      }

      const searchTerm = this.memberSearch.trim().toLowerCase();

      return this.filteredMembers.filter(member => {
        const name = member.name ? member.name.toLowerCase() : '';
        const email = member.email ? member.email.toLowerCase() : '';

        return name.includes(searchTerm) || email.includes(searchTerm);
      });
    }
  },
  methods: {
    splitCreatedAt(dateString, i) {
      if (dateString) {
        return dateString.split(' - ')[i]; // Adjust the index [0] or [1] based on which part you need
      }
      return '';
    },
    webinarLabel(webinar) {
      return `${webinar.title} - ${webinar.nth_course}`;
    },
    removeAllFromFinalTable() {
      this.finalMembers = [];
    },
    fetchWebinars() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_webinars.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.webinars = response.data.webinars || [];
        })
        .catch(error => {
          console.error('Error fetching webinars:', error);
        });
    },
    fetchProducts() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_products.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.products = response.data.webinars;
        })
        .catch(error => {
          console.error('Error fetching products:', error);
        });
    },
    fetchMembers() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/robot_users_info.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.members = response.data.map(user => ({
            name: user.FirstNameLastName,
            email: user.EMailAddress,
            source: user.Source === 'Robot' ? 'ربات' : 'وبسایت',
            CurrentJob: user.CurrentJob,
            StudyField: user.StudyField,
            EducationLevel: user.EducationLevel,
            CountryStateResidence: user.CountryStateResidence,
            emailStatus: '' // Initialize the email status
          }));
        })
        .catch(error => {
          console.error('Error fetching members:', error);
        });
    },
    fetchAttendees() {
      this.fetchMembers();
    },
    toggleMemberSelection(member) {
      const index = this.finalMembers.findIndex(finalMember => finalMember.email === member.email);
      if (index === -1) {
        this.finalMembers.push({ ...member, emailStatus: '' });
      } else {
        this.finalMembers.splice(index, 1);
      }
    },
    removeFromFinalTable(index) {
      this.finalMembers.splice(index, 1);
    },
    sendMessages() {
      this.finalMembers.forEach(member => {
        member.emailStatus = member.source === 'ربات' ? 'در حال ارسال پیام' : 'در حال ارسال ایمیل';

        if (member.source === 'ربات') {
          this.sendTelegramMessage(member);
        } else {
          this.sendEmail(member);
        }
      });
    },
    sendTelegramMessages() {
      this.finalMembers.forEach(member => {
        if (member.source === 'ربات') {
          member.emailStatus = 'در حال ارسال پیام تلگرام';
          this.sendTelegramMessage(member);
        } else {
          member.emailStatus = 'تلگرام ندارد';
        }
      });
    },
    sendEmails() {
      this.finalMembers.forEach(member => {
        member.emailStatus = 'در حال ارسال ایمیل';
        this.sendEmail(member);
      });
    },
    sendTelegramMessage(member) {
      const token = localStorage.getItem('jwt');
      const telegramMessage = `
        ${htmlToText(this.messageContent, {
          wordwrap: 130,
          uppercaseHeadings: false,
          singleNewLineParagraphs: true,
          tags: {
            a: { format: 'inline' },
            p: { format: 'block' }
          }
        })}
      `;

      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/send_telegram_using_email_message.php',
          {
            email: member.email,
            text: telegramMessage,
            title: "پیام ارسالی از طریق مارکتینگ"
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(response => {
          member.emailStatus = response.data.success ? 'پیام تلگرام ارسال شد' : 'پیام ارسال نشد';
        })
        .catch(() => {
          member.emailStatus = 'پیام ارسال نشد';
        });
    },
    sendEmail(member) {
      const token = localStorage.getItem('jwt');

      const emailContent = `
        <div style='text-align:right!important;direction:rtl!important;font-family:Tahoma!important;font-size:13px;'>
        ${this.messageContent}
        </div>
      `;
      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/send_email.php',
          {
            name: member.name,
            email: member.email,
            subject: this.emailSubject,
            content: emailContent
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(response => {
          member.emailStatus = response.data.success ? 'ایمیل ارسال شد' : 'ایمیل ارسال نشد';
        })
        .catch(() => {
          member.emailStatus = 'ایمیل ارسال نشد';
        });
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        this.filteredMembersBySearch.forEach(member => {
          if (!this.finalMembers.some(finalMember => finalMember.email === member.email)) {
            this.finalMembers.push({ ...member, emailStatus: '' });
          }
        });
      } else {
        this.finalMembers = this.finalMembers.filter(
          finalMember => !this.filteredMembersBySearch.some(member => member.email === finalMember.email)
        );
      }
    },
    isSelected(member) {
      return this.finalMembers.some(finalMember => finalMember.email === member.email);
    },
    showMoreInfo(member) {
      this.selectedMember = member;
      this.showUserTransactions(member.email); // Fetch transactions before showing the modal
      const modalElement = document.getElementById('moreInfoModal');
      const moreInfoModal = new Modal(modalElement);
      moreInfoModal.show();
    },
    showUserTransactions(email) {
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/list_user_transactions.php', {
        email: email
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (Array.isArray(response.data)) {
          this.userTransactions = response.data.filter(transaction => transaction.product_name);
        } else {
          console.error('Expected an array but received:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching user transactions:', error);
      });
    }
  },
  mounted() {
    this.fetchWebinars();
    this.fetchProducts();
    this.fetchMembers(); // Fetch all members when component is mounted
  }
};
</script>



<style>
.container {
  width: 100% !important;
  max-width: 100% !important;
  font-size: 12.5px !important;
  margin-top: 20px;
}


/* Table container for "لیست اعضا" to enable scrolling */
.table-container {
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-top: 10px;
}

/* 5" Displays (typically around 640x360) */
@media screen and (max-width: 640px) and (max-height: 360px) {
  .table-container {
    max-height: 100px;
  }
}

/* 7" Displays (typically around 800x480) */
@media screen and (max-width: 800px) and (max-height: 480px) {
  .table-container {
    max-height: 150px;
  }
}

/* 9" Displays (typically around 1024x600) */
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .table-container {
    max-height: 200px;
  }
}

/* 10" Displays (typically around 1280x720 or 1280x800) */
@media screen and (max-width: 1280px) and (max-height: 800px) {
  .table-container {
    max-height: 220px;
  }
}

/* 13" Displays - HD (1366x768) */
@media screen and (min-width: 1366px) and (max-width: 1366px) and (max-height: 768px) {
  .table-container {
    max-height: 250px;
  }
}

/* 15" Displays - Full HD (1920x1080) */
@media screen and (min-width: 1920px) and (max-width: 1920px) and (max-height: 1080px) {
  .table-container {
    max-height: 300px;
  }
}

/* 20" Displays - 2K (2560x1440) */
@media screen and (min-width: 2560px) and (max-width: 2560px) and (max-height: 1440px) {
  .table-container {
    max-height: 350px;
  }
}

/* 22" Displays - 3K (2880x1620) */
@media screen and (min-width: 2880px) and (max-width: 2880px) and (max-height: 1620px) {
  .table-container {
    max-height: 400px;
  }
}

/* 23" Displays - 4K (3840x2160) */
@media screen and (min-width: 3840px) and (max-width: 3840px) and (max-height: 2160px) {
  .table-container {
    max-height: 500px;
  }
}

/* 25" Displays - 4K (3840x2160) */
@media screen and (min-width: 3840px) and (max-width: 3840px) and (max-height: 2160px) {
  .table-container {
    max-height: 550px;
  }
}


.table {
  margin-bottom: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Adjust the search input and button margin */
.form-control.mb-2 {
  margin-bottom: 10px;
}

.btn-success {
  margin-top: 10px;
  margin-bottom: 30px;
}
.ck-editor__editable {
  min-height: 150px; /* Default minimum height */
  max-height: 100%; /* Allow to grow indefinitely */
  resize: vertical; /* Allow vertical resizing */
  overflow: auto; /* Ensure scrollbars appear if needed */
}


</style>
