<template>
  <div class="container mt-5">
    <div class="row">

        <div class="col-lg-12" style="margin-bottom:25px;">
          <div class="card" style="padding:10px 15px;border-radius:50px;">
            <div style="float:right;width:100%;">
            
              
              <div style="float:right;margin-right:10px;margin-top:8px;">
<!-- Add this section inside the `<div class="col-lg-12 card" style="padding-top:17px;margin-bottom:15px;padding-right:15px;padding-bottom:8px;">` -->
  <div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" id="incompleteProfile" v-model="incompleteProfileFilter" @change="applyFilters">
  <label class="form-check-label" for="incompleteProfile">نمایش پروفایل‌های ناقص</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" id="robotUsers" v-model="robotUserFilter" @change="applyFilters">
  <label class="form-check-label" for="robotUsers">نمایش کاربران ربات</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" id="websiteUsers" v-model="websiteUserFilter" @change="applyFilters">
  <label class="form-check-label" for="websiteUsers">نمایش کاربران وب‌سایت</label>
</div>
</div>
            <div style="float:left;width:20%">


          <input type="text" v-model="searchQuery" placeholder="جستجو: نام، ایمیل، شماره همراه، ..." class="form-control form-search" @input="applyFilters" />



              </div>
              </div>
          </div>
        </div>



<div class="col-lg-12 card" style="padding-top:17px;margin-bottom:15px;padding-right:15px;padding-bottom:8px;">


        <div v-if="showUsersTable">
     <button v-if="selectedAttendees.length > 0" @click="showCustomTelegramModal" class="btn btn-secondary mt-3" style="margin-right:5px;">ارسال پیام تلگرام دلخواه</button>
            <button v-if="selectedAttendees.length > 0" @click="showCustomEmailModal" class="btn btn-secondary mt-3" style="margin-right:5px;">ارسال ایمیل دلخواه</button>


          <div v-if="users.length > 0" class="table-responsive mt-3" style="display: block; width:100%; max-height: 650px; overflow-y: auto;">
          
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"><input type="checkbox" @change="selectAll($event)" /></th>
                  <th scope="col">ردیف</th>
                  <th scope="col">نام</th>
                  <th scope="col">ایمیل</th>
                  <th scope="col">شماره همراه</th>
                  <th scope="col">شغل</th>
                  <th scope="col">رشته تحصیلی</th>
                  <th scope="col">مقطع تحصیلی</th>
                  <th scope="col">کشور/استان</th>
                  <th scope="col">عملیات</th>
                  <!-- Add to table header for webinars column with sorting -->
                  <th scope="col">
                    شرکت در وبینارها
                    <button @click="toggleSort('webinars')" class="btn btn-link btn-sm">
                      <svg v-if="sortOrder === 'asc' && sortKey === 'webinars'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="12" y1="5" x2="12" y2="19"/>
                        <line x1="18" y1="13" x2="12" y2="19"/>
                        <line x1="6" y1="13" x2="12" y2="19"/>
                      </svg>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="12" y1="5" x2="12" y2="19"/>
                        <line x1="18" y1="11" x2="12" y2="5"/>
                        <line x1="6" y1="11" x2="12" y2="5"/>
                      </svg>
                    </button>
                  </th>

                  <th scope="col">تراکنش‌ها</th> <!-- Add this line -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, i) in users" :key="user.UserID">
                  <td><input type="checkbox" v-model="selectedAttendees" :value="user" /></td>

    <td>{{ i + 1 }}</td> <!-- Row number displayed here -->
                  <td @click="copyToClipboard(user.FirstNameLastName)" style="cursor: pointer;">{{ user.FirstNameLastName }}</td>
                  <td @click="copyToClipboard(user.EMailAddress)" style="cursor: pointer;">{{ user.EMailAddress }}</td>
                  <td @click="copyToClipboard(user.MobileNumber)" style="cursor: pointer;">{{ user.MobileNumber }}</td>
                  <td @click="copyToClipboard(user.CurrentJob)" style="cursor: pointer;">{{ user.CurrentJob }}</td>
                  <td @click="copyToClipboard(user.StudyField)" style="cursor: pointer;">{{ user.StudyField }}</td>
                  <td @click="copyToClipboard(user.EducationLevel)" style="cursor: pointer;">{{ user.EducationLevel }}</td>
                  <td @click="copyToClipboard(user.CountryStateResidence)" style="cursor: pointer;">{{ user.CountryStateResidence }}</td>
                  
                  <td>{{ user.emailStatus || '' }}</td>
                  <td>

  <button class="btn btn-info" @click="showUserWebinars(user.EMailAddress)">
    {{ webinarCounts[user.EMailAddress] || 0 }}
  </button>
                  </td>
                  <td>
                    <button class="btn btn-primaryy" @click="showUserTransactions(user.EMailAddress, user.FirstNameLastName)">تراکنش‌ها</button>
                  </td>
                </tr>
              </tbody>
            </table>
       

          </div>
          <div v-if="error" class="alert alert-danger mt-3">
            {{ error }}
          </div>
        </div>


        <div v-if="showTransactionsTable">
          <!-- User Transactions Table -->
          <div v-if="userTransactions.length > 0" class="table-responsive">
          
    <h3>تراکنش‌های {{ selectedUserName }}</h3> <!-- Add this line -->
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">محصول</th>
                  <th scope="col">مقدار</th>
                  <th scope="col">واحد</th>
                  <th scope="col">تاریخ</th>
                  <th scope="col">ساعت</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="transaction in userTransactions" :key="transaction.id">
                  <td>{{ transaction.product_name }}</td>
                  <td>{{ transaction.amount }}</td>
                  <td>{{ euroOrTomans(transaction.abroad) }}</td>
                  <td>{{ splitCreatedAt(transaction.created_at, 1) }}</td>
                  <td>{{ splitCreatedAt(transaction.created_at, 0) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p>تراکنشی یافت نشد</p>
          </div>
          <br><br>
          <button class="btn btn-secondary" @click="hideTransactionsTable">بازگشت</button>
        </div>



      </div>
    </div>

<!-- Add Modal for displaying webinars -->
<div class="modal fade" id="webinarsModal" tabindex="-1" role="dialog" aria-labelledby="webinarsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="webinarsModalLabel">وبینارهای شرکت‌کرده</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-if="selectedUserWebinars.length > 0" class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">نام وبینار</th>
                <th scope="col">تاریخ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="webinar in selectedUserWebinars" :key="webinar.id">
                <td>{{ webinar.product_name }}</td>
                <td>{{ splitCreatedAt(webinar.created_at, 1) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>وبیناری یافت نشد</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
      </div>
    </div>
  </div>
</div>

    <!-- Custom Telegram Modal -->
    <div class="modal fade" id="customTelegramModal" tabindex="-1" role="dialog" aria-labelledby="customTelegramModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customTelegramModalLabel">ارسال پیام تلگرام دلخواه به کاربران</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="customTelegramContent">محتوا</label>
              <ckeditor :editor="editor" v-model="customTelegramContent" :config="editorConfig"></ckeditor>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideCustomTelegramModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendCustomTelegramMessages" :disabled="isSubmitting">ارسال پیام</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Custom Email Modal -->
    <div class="modal fade" id="customEmailModal" tabindex="-1" role="dialog" aria-labelledby="customEmailModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customEmailModalLabel">ارسال ایمیل دلخواه به کاربران</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="customEmailSubject">موضوع</label>
              <input v-model="customEmailSubject" type="text" class="form-control" id="customEmailSubject" required />
            </div>
            <div class="form-group">
              <label for="customEmailContent">محتوا</label>
              <ckeditor :editor="editor" v-model="customEmailContent" :config="editorConfig"></ckeditor>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideCustomEmailModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendCustomEmails" :disabled="isSubmitting">ارسال ایمیل</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Toast -->
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="copyToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">عملیات</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal, Toast } from 'bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { htmlToText } from 'html-to-text';

export default {
    data() {
    return {
      users: [],
      selectedAttendees: [],
      error: '',
      sortKey: '',
      sortOrder: 'asc',
      toastMessage: '',
      pageSize: 9,
      currentPage: 1,
      pageSizes: [9, 25, 50, 100],
      customEmailSubject: '',
      customEmailContent: '',
      customTelegramContent: '',
      webinarCounts: {}, // For storing webinar counts for each user  
      isSubmitting: false,
      selectedUserWebinars: [], // For storing user's webinars
      showWebinarsTable: false, // For controlling the display of webinars table
      searchQuery: '',
      editor: ClassicEditor,

      incompleteProfileFilter: false, // Add this line
    robotUserFilter: false,         // Add this line
    websiteUserFilter: false,       // Add this line
      showUsersTable: true,
      showTransactionsTable: false,
      editorConfig: {
        language: 'ar', // Set the language to Arabic (or any RTL language)
        contentsLangDirection: 'rtl', // Set the content direction to RTL
        alignment: {
          options: ['right', 'left'] // Only allow right alignment
        }
      },
      userTransactions: [], // Add this line
      userTransactionsModalInstance: null, // Add this line
    };
  },
  computed: {
  totalPages() {
    return Math.ceil(this.filteredUsers.length / this.pageSize);
  },

  paginatedPages() {
    const total = this.totalPages;
    const current = this.currentPage;
    const delta = 2; // Number of pages to show around current page
    const range = [];
    const rangeWithDots = [];
    let l;

    range.push(1);
    for (let i = current - delta; i <= current + delta; i++) {
      if (i >= 2 && i <= total - 1) {
        range.push(i);
      }
    }
    range.push(total);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  },

  paginatedUsers() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.filteredUsers.slice(start, end).map((user, index) => {
      user.rowNumber = start + index + 1; // Calculate the row number
      return user;
    });
  },

  filteredUsers() {
    let users = this.users;

    if (this.searchQuery) {
      const query = this.searchQuery.toLowerCase();
      users = users.filter(user => {
        return (
          (user.FirstNameLastName && user.FirstNameLastName.toLowerCase().includes(query)) ||
          (user.EMailAddress && user.EMailAddress.toLowerCase().includes(query)) ||
          (user.UserID && user.UserID.toLowerCase().includes(query)) ||
          (user.MobileNumber && user.MobileNumber.toLowerCase().includes(query)) ||
          (user.CurrentJob && user.CurrentJob.toLowerCase().includes(query)) ||
          (user.StudyField && user.StudyField.toLowerCase().includes(query)) ||
          (user.EducationLevel && user.EducationLevel.toLowerCase().includes(query)) ||
          (user.CountryStateResidence && user.CountryStateResidence.toLowerCase().includes(query)) ||
          (user.IranWallet && user.IranWallet.toLowerCase().includes(query)) ||
          (user.EuroWallet && user.EuroWallet.toLowerCase().includes(query))
        );
      });
    }

    if (this.incompleteProfileFilter) {
      users = users.filter(user => !this.isProfileComplete(user));
    }

    if (this.robotUserFilter) {
      users = users.filter(user => user.Source === 'Robot');
    }

    if (this.websiteUserFilter) {
      users = users.filter(user => user.Source === 'Website');
    }

    return users;
  }
},
  methods: {
  toggleSort(key) {
    if (this.sortKey === key) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortKey = key;
      this.sortOrder = 'asc';
    }
    this.sortUsers();
  },

  sortUsers() {
    this.filteredUsers.sort((a, b) => {
      let result = 0;
      if (this.sortKey === 'webinars') {
        result = (this.webinarCounts[a.EMailAddress] || 0) - (this.webinarCounts[b.EMailAddress] || 0);
      }
      // other sorting cases...
      return this.sortOrder === 'asc' ? result : -result;
    });
  },
  
  fetchUsers() {
    const token = localStorage.getItem('jwt');
    console.log('Fetching robot users info...');
    axios.post('https://dashboard.ordmenpodcast.com/v/robot_users_info.php', {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      console.log('Users fetched:', response.data);
      this.users = response.data;
      this.computeWebinarCounts(); // Compute webinar counts after fetching users
    })
    .catch(error => {
      console.error('Error fetching users:', error);
      this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
    });
  },
  copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Copied to clipboard:', text);
      this.toastMessage = 'به کلیپ‌بورد کپی شد!';
      this.showToast();
    }).catch(err => {
      console.error('Failed to copy:', err);
    });
  },

computeWebinarCounts() {
  const token = localStorage.getItem('jwt');
  const userEmails = this.users.map(user => user.EMailAddress);

  userEmails.forEach(email => {
    axios.post('https://dashboard.ordmenpodcast.com/v/list_user_transactions.php', {
      email: email
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      const transactions = response.data;
      if (Array.isArray(transactions)) {
        const webinarCount = transactions.filter(transaction => transaction.product_name && transaction.product_name !== 'مشاوره' && transaction.product_name !== 'شارژ حساب' && transaction.product_name !== 'حمایت مالی').length;
        this.webinarCounts[email] = webinarCount;
      } else {
        console.error('Error: Expected an array but got', typeof transactions);
      }
    })
    .catch(error => {
      console.error('Error fetching user webinars:', error);
    });
  });
},
    formatNum(number) {
      // Convert the float to an integer
    let intNumber = Math.floor(number);

    // Convert the number to a string and add commas
    let formattedNumber = intNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "،");

    return formattedNumber;
    },

    formatDate(dateString) {
      if (!dateString) {
        return 'تاریخ وارد نشده'; // Return a default value if dateString is undefined
      }

      const shamsiMonths = [
        'فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور',
        'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'
      ];
      const [year, month, day] = dateString.split('-');
      const monthName = shamsiMonths[parseInt(month) - 1];
      return `${day} ${monthName} ${year}`;
    },
  selectAll(event) {
    if (event.target.checked) {
      this.selectedAttendees = [...this.users];
    } else {
      this.selectedAttendees = [];
    }
  },
  showToast() {
    const toastElement = document.getElementById('copyToast');
    this.toastInstance = new Toast(toastElement);
    this.toastInstance.show();
  },
  showCustomTelegramModal() {
    const modalElement = document.getElementById('customTelegramModal');
    this.customTelegramInstance = new Modal(modalElement);
    this.customTelegramInstance.show();
  },
  hideCustomTelegramModal() {
    if (this.customTelegramInstance) {
      this.customTelegramInstance.hide();
    }
  },
  sendCustomTelegramMessages() {
    this.isSubmitting = true;
    const token = localStorage.getItem('jwt');
    console.log('Sending custom Telegram messages to selected users...');

    const promises = this.selectedAttendees.map((user, index) => {
      const telegramMessage = `
        ${user.FirstNameLastName} عزیز سلام؛\n
        ${htmlToText(this.customTelegramContent, {
          wordwrap: 130,
          uppercaseHeadings: false,
          singleNewLineParagraphs: true,
          tags: { // Optional custom tag handling
            'a': { format: 'inline' },
            'p': { format: 'block' }
          }
        })}
      `;
      console.log(telegramMessage);

      // Set the email status to "در حال ارسال پیام تلگرام..." before sending
      user.emailStatus = 'در حال ارسال پیام تلگرام...';

      return new Promise((resolve) => setTimeout(resolve, index * 1500)).then(() => {
        return axios.post('https://dashboard.ordmenpodcast.com/v/send_telegram_message.php', {
          chatID: user.UserID,
          text: telegramMessage
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          console.log('Telegram message sent to:', user, response.data);
          if (response.data.success) {
            user.emailStatus = 'پیام تلگرامی ارسال شد.';
          } else {
            console.error('Telegram API error:', response.data);
            user.emailStatus = 'عدم امکان ارسال پیام تلگرامی';
            throw new Error(response.data.error || 'Failed to send Telegram message');
          }
        })
        .catch(error => {
          console.error('Error sending Telegram message to:', user, error);
          user.emailStatus = 'عدم امکان ارسال پیام تلگرامی';
          throw error;
        });
      });
    });

    Promise.all(promises)
      .then(() => {
        console.log('All custom Telegram messages sent successfully.');
        this.toastMessage = 'پیام‌های تلگرام با موفقیت ارسال شدند.';
        this.showToast();
        this.hideCustomTelegramModal();
      })
      .catch(error => {
        console.error('Error sending custom Telegram messages:', error);
        this.error = error.message || 'Failed to send custom Telegram messages to selected users.';
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  },
  showCustomEmailModal() {
    const modalElement = document.getElementById('customEmailModal');
    this.customEmailInstance = new Modal(modalElement);
    this.customEmailInstance.show();
  },
  hideCustomEmailModal() {
    if (this.customEmailInstance) {
      this.customEmailInstance.hide();
    }
  },
  sendCustomEmails() {
    this.isSubmitting = true;
    const token = localStorage.getItem('jwt');
    console.log('Sending custom emails to selected users...');
    
    const promises = this.selectedAttendees.map((user, index) => {
      const emailContent = `
        <div style='text-align:right!important;direction:rtl!important;font-family:Tahoma!important;font-size:13px;'>
        ${user.FirstNameLastName} عزیز سلام؛
        <br><br>
        ${this.customEmailContent}
        </div>
      `;
      
      // Set the email status to "در حال ارسال ایمیل..." before sending
      user.emailStatus = 'در حال ارسال ایمیل...';

      return new Promise((resolve) => setTimeout(resolve, index * 3000)).then(() => {
        return axios.post('https://dashboard.ordmenpodcast.com/v/send_email.php', {
          name: user.FirstNameLastName,
          email: user.EMailAddress,
          subject: this.customEmailSubject,
          content: emailContent
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          console.log('Email sent to:', user, response.data);
          if (response.data.success) {
            // Update the email status to "ایمیل ارسال شد."
            user.emailStatus = 'ایمیل ارسال شد.';
          } else {
            throw new Error(response.data.error || 'Failed to send email');
          }
        })
        .catch(error => {
          console.error('Error sending email to:', user, error);
          // Update the email status to "عدم امکان ارسال ایمیل"
          user.emailStatus = 'عدم امکان ارسال ایمیل';
          throw error;
        });
      });
    });

    Promise.all(promises)
      .then(() => {
        console.log('All custom emails sent successfully.');
        this.toastMessage = 'ایمیل‌های دلخواه با موفقیت ارسال شدند.';
        this.showToast();
        this.hideCustomEmailModal();
      })
      .catch(error => {
        console.error('Error sending custom emails:', error);
        this.error = error.message || 'Failed to send custom emails to selected users.';
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  },

    splitCreatedAt(dateString, i) {
      if (dateString) {
        return dateString.split(' - ')[i]; // Adjust the index [0] or [1] based on which part you need
      }
      return '';
    },
    euroOrTomans(isAbroad) {
      if (isAbroad == 1){
        return "یورو";
      } else {
        return "تومان";
      }
    },
  applyFilters() {
    // Filters are applied through computed property 'filteredUsers'
  },
  resetFilters() {
    this.searchQuery = '';
  },
  isProfileComplete(user) {
    return user.MobileNumber && user.CurrentJob && user.StudyField && user.EducationLevel && user.CountryStateResidence;
  },
// Methods:
showUserWebinars(email) {
  const token = localStorage.getItem('jwt');
  axios.post('https://dashboard.ordmenpodcast.com/v/list_user_transactions.php', {
    email: email
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => {
    this.selectedUserWebinars = response.data.filter(transaction => transaction.product_name && transaction.product_name !== 'مشاوره' && transaction.product_name !== 'شارژ حساب' && transaction.product_name !== 'حمایت مالی');
    const modalElement = document.getElementById('webinarsModal');
    this.userTransactionsModalInstance = new Modal(modalElement);
    this.userTransactionsModalInstance.show();
  })
  .catch(error => {
    console.error('Error fetching user webinars:', error);
    this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
  });
},


  showUserTransactions(email, name) { // Modify this line
    const token = localStorage.getItem('jwt');
    console.log('Fetching transactions for email:', email);

    axios.post('https://dashboard.ordmenpodcast.com/v/list_user_transactions.php', {
      email: email
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      console.log('User transactions fetched:', response.data);
      this.userTransactions = response.data;
      this.selectedUserName = name; // Add this line
      this.showUsersTable = false;
      this.showTransactionsTable = true;
    })
    .catch(error => {
      console.error('Error fetching user transactions:', error);
      this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
    });
  },
  hideTransactionsTable() {
    this.showUsersTable = true;
    this.showTransactionsTable = false;
  },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    changePageSize() {
      this.currentPage = 1;
    }
},
  mounted() {
    this.fetchUsers();
  }
};
</script>

<style scoped>

.container{
    width: 100% !important;
    max-width: 100% !important;
    font-size:12.5px!important;
  }

</style>