<template>
  <div>
    <div class="container mt-5">
      <div class="row">

        <div class="col-lg-12" style="margin-bottom:25px;">
          <div class="card" style="padding:10px 15px;border-radius:50px;">
            <div style="float:right;width:100%;">
            <div style="float:right;width:40%">
              <div style="float:right;">
                <button @click="downloadCSV" class="btn btn-primaryy mx-1">دانلود CSV</button>
              </div>
              <div style="float:right;">
                <button @click="openModal" class="btn btn-primaryy mx-1">افزودن تراکنش</button>
              </div>

            </div>


            <div style="float:left;width:20%">

              <input type="text" v-model="filter.search" @input="applyFilters" placeholder="جستجوی نام، ایمیل" class="form-control form-search">
              </div>
              </div>
          </div>
        </div>


      
        <div class="card col-lg-9 col-md-8 col-12" style="padding-top:15px;">
  <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
  <div v-else class="table-responsive" style="overflow-x: auto;">
    <table class="table table-striped" style="width: 100%; table-layout: auto; max-height: 650px; overflow-y: auto;">
      <thead style="position: sticky; top: 0; background-color: #FFF; z-index: 1;">
        <tr>
          <th scope="col">ردیف</th>
          <th scope="col">ایمیل</th>
          <th scope="col">نام</th>
          <th scope="col">نام محصول</th>
          <th scope="col">
            مقدار
            <button @click="toggleSort('amount')" class="btn btn-link btn-sm">
              <svg v-if="sortOrder === 'asc' && sortKey === 'amount'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="12" y1="5" x2="12" y2="19"/>
                <line x1="18" y1="13" x2="12" y2="19"/>
                <line x1="6" y1="13" x2="12" y2="19"/>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="12" y1="5" x2="12" y2="19"/>
                <line x1="18" y1="11" x2="12" y2="5"/>
                <line x1="6" y1="11" x2="12" y2="5"/>
              </svg>
            </button>
          </th>
          <th scope="col">واحد</th>
          <th scope="col">
            تاریخ
            <button @click="toggleSort('created_at')" class="btn btn-link btn-sm">
              <svg v-if="sortOrder === 'asc' && sortKey === 'created_at'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="12" y1="5" x2="12" y2="19"/>
                <line x1="18" y1="13" x2="12" y2="19"/>
                <line x1="6" y1="13" x2="12" y2="19"/>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="12" y1="5" x2="12" y2="19"/>
                <line x1="18" y1="11" x2="12" y2="5"/>
                <line x1="6" y1="11" x2="12" y2="5"/>
              </svg>
            </button>
          </th>
          <th scope="col">ساعت</th>
          <th scope="col">اطلاعات بیشتر</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(transaction, i) in transactions" :key="transaction.id">
          <td>{{ (currentPage - 1) * pageSize + i + 1 }}</td>
          <td>{{ transaction.email }}</td>
          <td>{{ transaction.name }}</td>
          <td>{{ transaction.product_name }}</td>
          <td>{{ formatNum(transaction.amount) }}</td>
          <td>{{ euroOrTomans(transaction.abroad) }}</td>
          <td>{{ splitCreatedAt(transaction.created_at, 1) }}</td>
          <td>{{ splitCreatedAt(transaction.created_at, 0) }}</td>
          <td>
            <button class="btn btn-primaryy btn-sm" @click="showTransactionDetails(transaction)">
              اطلاعات بیشتر
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="col-lg-3 col-md-4 col-12">
          <!-- Add this table for statistics at the top of the table -->
          
<div class="card">
  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
      <li class="nav-item" role="presentation">
        <a href="#tabs-all-stats" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">تراکنش‌ها</a>
      </li>
      <li class="nav-item" role="presentation">
        <a href="#tabs-stats" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">تراکنش‌های ماه</a>
      </li>
      <li class="nav-item" role="presentation">
        <a href="#tabs-filters" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">فیلترها</a>
      </li>
    </ul>
  </div>
  <div class="card-body">
    <div class="tab-content">
      <div class="tab-pane fade active show" id="tabs-all-stats" role="tabpanel">
        <div class="mt-4">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>ویژگی</th>
                <th class="centeredtext">مقدار</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>تعداد تراکنش‌ها</td>
                <td class="centeredtext">{{ transactions.length }}</td>
              </tr>
              <tr>
                <td>جمع مقدار تومانی</td>
                <td class="centeredtext">{{ formatNum(totalAmountTomansAll) }}</td>
              </tr>
              <tr>
                <td>جمع مقدار یورویی</td>
                <td class="centeredtext">{{ formatNum(totalAmountEurosAll) }}</td>
              </tr>
              <tr>
                <td>تعداد افراد</td>
                <td class="centeredtext">{{ uniqueEmailsAll.length }}</td>
              </tr>
              <tr>
                <td>تعداد تراکنش‌های آفلاین</td>
                <td class="centeredtext">{{ offlineTransactionsCountAll }}</td>
              </tr>
              <tr>
                <td>تعداد تراکنش‌های آنلاین</td>
                <td class="centeredtext">{{ onlineTransactionsCountAll }}</td>
              </tr>
              <tr>
                <td>تعداد محصولات خریداری‌شده</td>
                <td class="centeredtext">{{ totalProductsAll }}</td>
              </tr>
              <tr v-for="(count, index) in barCountAll" :key="index">
                <td>تعداد بار {{ index }}</td>
                <td class="centeredtext">{{ count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tab-pane fade" id="tabs-stats" role="tabpanel">
        <!-- Monthly Stats -->
        <div class="mt-4">
          <div class="mb-3">
            <label for="monthSelect" class="form-label">انتخاب ماه:</label>
            <select v-model="selectedMonth" @change="updateMonthlyStats" id="monthSelect" class="form-select">
              <option value="01">فروردین</option>
              <option value="02">اردیبهشت</option>
              <option value="03">خرداد</option>
              <option value="04">تیر</option>
              <option value="05">مرداد</option>
              <option value="06">شهریور</option>
              <option value="07">مهر</option>
              <option value="08">آبان</option>
              <option value="09">آذر</option>
              <option value="10">دی</option>
              <option value="11">بهمن</option>
              <option value="12">اسفند</option>
            </select>
          </div>
          
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>ویژگی</th>
                <th class="centeredtext">مقدار</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>تعداد تراکنش‌ها</td>
                <td class="centeredtext">{{ monthlyStats.transactionCount }}</td>
              </tr>
              <tr>
                <td>جمع مقدار تومانی</td>
                <td class="centeredtext">{{ formatNum(monthlyStats.totalAmountTomans) }}</td>
              </tr>
              <tr>
                <td>جمع مقدار یورویی</td>
                <td class="centeredtext">{{ formatNum(monthlyStats.totalAmountEuros) }}</td>
              </tr>
              <tr>
                <td>تعداد افراد</td>
                <td class="centeredtext">{{ monthlyStats.uniqueUsersCount }}</td>
              </tr>
              <tr>
                <td>تعداد تراکنش‌های آفلاین</td>
                <td class="centeredtext">{{ monthlyStats.offlineTransactionsCount }}</td>
              </tr>
              <tr>
                <td>تعداد تراکنش‌های آنلاین</td>
                <td class="centeredtext">{{ monthlyStats.onlineTransactionsCount }}</td>
              </tr>
              <tr>
                <td>تعداد محصولات خریداری‌شده</td>
                <td class="centeredtext">{{ monthlyStats.uniqueProductsCount }}</td>
              </tr>
              <tr v-for="(count, index) in monthlyStats.barCount" :key="index">
                <td>تعداد بار {{ index }}</td>
                <td class="centeredtext">{{ count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tab-pane fade" id="tabs-filters" role="tabpanel">
        <div class="mt-4">
        
          <label for="abroad" class="form-label">موقعیت جغرافیایی</label>
          <select v-model="filter.abroad" id="abroad" class="form-select mb-2">
            <option value="">همه</option>
            <option value="0">داخل کشور</option>
            <option value="1">خارج کشور</option>
          </select>


          <label for="start_time" class="form-label">زمان شروع</label>
          <date-picker type="datetime" v-model="filter.start_time" id="start_time" class="form-control mb-2"></date-picker>

          <label for="end_time" class="form-label">زمان پایان</label>
          <date-picker type="datetime" v-model="filter.end_time" id="end_time" class="form-control mb-2"></date-picker>

          <div class="d-flex justify-content-between mb-2">
            <button @click="resetFilters" class="btn btn-secondary">حذف فیلترها</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
      </div>
    </div>

<div class="modal fade" id="transactionDetailsModal" tabindex="-1" role="dialog" aria-labelledby="transactionDetailsModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="transactionDetailsModalLabel">اطلاعات بیشتر</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">ویژگی</th>
              <th scope="col">مقدار</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>بار چندم؟</td>
              <td>{{ selectedTransaction.nth_time }}</td>
            </tr>
            <tr>
              <td>آفلاین؟</td>
              <td>{{ getYesNo(selectedTransaction.offline) }}</td>
            </tr>
            <tr>
              <td>شماره کارت</td>
              <td>{{ selectedTransaction.card_pan }}</td>
            </tr>
            <tr>
              <td>شناسه مرجع</td>
              <td>{{ selectedTransaction.ref_id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
      </div>
    </div>
  </div>
</div>

<!-- Transaction Modal -->
<div class="modal fade" id="transactionModal" tabindex="-1" aria-labelledby="transactionModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="transactionModalLabel">افزودن تراکنش</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submitTransaction">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="email" class="form-label">ایمیل</label>
                <input type="email" class="form-control" id="email" v-model="newTransaction.email" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="name" class="form-label">نام</label>
                <input type="text" class="form-control" id="name" v-model="newTransaction.name" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="product_id" class="form-label">شناسه محصول</label>
                <input type="text" class="form-control" id="product_id" v-model="newTransaction.product_id" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="amount" class="form-label">مقدار</label>
                <input type="number" class="form-control" id="amount" v-model="newTransaction.amount" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="paid" class="form-label">پرداخت شده</label>
                <select class="form-select" id="paid" v-model="newTransaction.paid" required>
                  <option value="1">بله</option>
                  <option value="0">خیر</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="mentorship" class="form-label">مشاوره</label>
                <select class="form-select" id="mentorship" v-model="newTransaction.mentorship" required>
                  <option value="1">بله</option>
                  <option value="0">خیر</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="walletCharge" class="form-label">شارژ کیف پول</label>
                <select class="form-select" id="walletCharge" v-model="newTransaction.walletCharge" required>
                  <option value="1">بله</option>
                  <option value="0">خیر</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="abroad" class="form-label">خارجی</label>
                <select class="form-select" id="abroad" v-model="newTransaction.abroad" required>
                  <option value="1">بله</option>
                  <option value="0">خیر</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="drop_tr" class="form-label">حذف شده؟</label>
                <select class="form-select" id="drop_tr" v-model="newTransaction.drop_tr" required>
                  <option value="1">بله</option>
                  <option value="0">خیر</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary">ثبت</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue3-persian-datetime-picker';
import jalaali from 'jalaali-js';
import { Modal } from 'bootstrap';

export default {
  name: 'TransactionsPage',
  components: { DatePicker },
  data() {
    return {
      transactions: [],
      filteredTransactions: [],
      selectedTransaction: {},

    sortOrder: 'asc',
    sortKey: '',
      filter: {
        search: '',
        product: '',
        product_name: '',
        mentorship: '',
        paid: '',
        walletCharge: '',
        abroad: '',
        drop_tr: '',
        start_time: '',
        end_time: '',
        nth_time: ''
      },
      newTransaction: {
        email: '',
        name: '',
        product_id: '',
        amount: '',
        paid: '',
        mentorship: '',
        walletCharge: '',
        abroad: '',
        drop_tr: ''
      },
      products: [], // Initialize as an empty array
      error: null,
      pageSize: 9,
      currentPage: 1,
      pageSizes: [9, 25, 50, 100],
      selectedMonth: '',
      monthlyStats: {
        transactionCount: 0,
        totalAmountTomans: 0,
        totalAmountEuros: 0,
        uniqueUsersCount: 0,
        offlineTransactionsCount: 0,
        onlineTransactionsCount: 0,
        uniqueProductsCount: 0,
        barCount: {}
      }
    };
  },
  watch: {
    'filter.search': 'applyFilters',
    'filter.product': 'applyFilters',
    'filter.product_name': 'applyFilters',
    'filter.mentorship': 'applyFilters',
    'filter.paid': 'applyFilters',
    'filter.walletCharge': 'applyFilters',
    'filter.abroad': 'applyFilters',
    'filter.drop_tr': 'applyFilters',
    'filter.start_time': 'applyFilters',
    'filter.end_time': 'applyFilters',
    'filter.nth_time': 'applyFilters'
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredTransactions.length / this.pageSize);
    },
    
    paginatedPages() {
      const total = this.totalPages;
      const current = this.currentPage;
      const delta = 2; // Number of pages to show around current page
      const range = [];
      const rangeWithDots = [];
      let l;

      range.push(1);
      for (let i = current - delta; i <= current + delta; i++) {
        if (i >= 2 && i <= total - 1) {
          range.push(i);
        }
      }
      range.push(total);

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredTransactions.slice(start, end);
    },
    totalAmountTomans() {
      return this.paginatedTransactions.reduce((sum, transaction) => {
        return transaction.abroad === '0' ? sum + parseFloat(transaction.amount) : sum;
      }, 0);
    },
    totalAmountEuros() {
      return this.paginatedTransactions.reduce((sum, transaction) => {
        return transaction.abroad === '1' ? sum + parseFloat(transaction.amount) : sum;
      }, 0);
    },
    uniqueEmails() {
      const emails = this.paginatedTransactions.map(transaction => transaction.email);
      return [...new Set(emails)];
    },
    offlineTransactionsCount() {
      return this.paginatedTransactions.filter(transaction => transaction.offline === '1').length;
    },
    onlineTransactionsCount() {
      return this.paginatedTransactions.filter(transaction => transaction.offline === '0').length;
    },
    totalProducts() {
      const uniqueProducts = new Set();
      this.paginatedTransactions.forEach(transaction => {
        const productName = transaction.product_name;
        if (productName && productName.trim() !== '') { // Check if productName is non-empty
          uniqueProducts.add(productName.trim());
        }
      });
      return uniqueProducts.size;
    },
    barCount() {
      const counts = {};
      this.paginatedTransactions.forEach(transaction => {
        const nthTime = transaction.nth_time;
        if (nthTime) { // Only count if nth_time is not empty or undefined
          if (counts[nthTime]) {
            counts[nthTime]++;
          } else {
            counts[nthTime] = 1;
          }
        }
      });
      return counts;
    },
    totalAmountTomansAll() {
      return this.transactions.reduce((sum, transaction) => {
        return transaction.abroad === '0' ? sum + parseFloat(transaction.amount) : sum;
      }, 0);
    },
    totalAmountEurosAll() {
      return this.transactions.reduce((sum, transaction) => {
        return transaction.abroad === '1' ? sum + parseFloat(transaction.amount) : sum;
      }, 0);
    },
    uniqueEmailsAll() {
      const emails = this.transactions.map(transaction => transaction.email);
      return [...new Set(emails)];
    },
    offlineTransactionsCountAll() {
      return this.transactions.filter(transaction => transaction.offline === '1').length;
    },
    onlineTransactionsCountAll() {
      return this.transactions.filter(transaction => transaction.offline === '0').length;
    },
    totalProductsAll() {
      const uniqueProducts = new Set();
      this.transactions.forEach(transaction => {
        const productName = transaction.product_name;
        if (productName && productName.trim() !== '') { // Check if productName is non-empty
          uniqueProducts.add(productName.trim());
        }
      });
      return uniqueProducts.size;
    },
    barCountAll() {
      const counts = {};
      this.transactions.forEach(transaction => {
        const nthTime = transaction.nth_time;
        if (nthTime) { // Only count if nth_time is not empty or undefined
          if (counts[nthTime]) {
            counts[nthTime]++;
          } else {
            counts[nthTime] = 1;
          }
        }
      });
      return counts;
    },
    distinctNthTimes() {
      return [...new Set(this.transactions.map(transaction => transaction.nth_time))].filter(nthTime => nthTime);
    },

  },
  created() {
    this.fetchTransactions();
    this.setDefaultMonth();
    this.fetchProducts(); // Fetch the list of products on component creation
  },
  methods: {
  sortTable(column, direction) {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.applyFilters();
  },
    formatNum(number) {
      // Convert the float to an integer
      let intNumber = Math.floor(number);

      // Convert the number to a string and add commas
      let formattedNumber = intNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "،");

      return formattedNumber;
    },
    setDefaultMonth() {
      const now = new Date();
      const { jm } = jalaali.toJalaali(now);
      const month = jm.toString().padStart(2, '0');
      this.selectedMonth = month;
      this.updateMonthlyStats();
    },
    updateMonthlyStats() {
      const monthTransactions = this.transactions.filter(transaction => {
        const jalaaliDate = transaction.created_at.split(' - ')[1];
        const jm = jalaaliDate.split('-')[1];
        return parseInt(jm, 10) === parseInt(this.selectedMonth, 10);
      });

      this.monthlyStats.transactionCount = monthTransactions.length;
      this.monthlyStats.totalAmountTomans = monthTransactions.reduce((sum, transaction) => {
        return transaction.abroad === '0' ? sum + parseFloat(transaction.amount) : sum;
      }, 0);
      this.monthlyStats.totalAmountEuros = monthTransactions.reduce((sum, transaction) => {
        return transaction.abroad === '1' ? sum + parseFloat(transaction.amount) : sum;
      }, 0);
      this.monthlyStats.uniqueUsersCount = new Set(monthTransactions.map(t => t.email)).size;
      this.monthlyStats.offlineTransactionsCount = monthTransactions.filter(t => t.offline === '1').length;
      this.monthlyStats.onlineTransactionsCount = monthTransactions.filter(t => t.offline === '0').length;
      this.monthlyStats.uniqueProductsCount = new Set(monthTransactions.map(t => t.product_name).filter(Boolean)).size;

      const barCount = {};
      monthTransactions.forEach(transaction => {
        const nthTime = transaction.nth_time;
        if (nthTime) {
          barCount[nthTime] = (barCount[nthTime] || 0) + 1;
        }
      });
      this.monthlyStats.barCount = barCount;
    },
    splitCreatedAt(dateString, i) {
      if (dateString) {
        return dateString.split(' - ')[i];
      }
      return '';
    },
    showTransactionDetails(transaction) {
      this.selectedTransaction = transaction;
      const modal = new Modal(document.getElementById('transactionDetailsModal'));
      modal.show();
    },
    fetchTransactions() {
      const token = localStorage.getItem('jwt');
      axios.get('https://dashboard.ordmenpodcast.com/v/list_transactions.php', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        this.transactions = response.data.filter(transaction => {
            return (
            transaction.donation === "1" ||
            transaction.SupportKidsEducation === "1"
            );
          });
        this.updateMonthlyStats(); // Update stats after fetching transactions
        this.applyFilters();
      })
      .catch(error => {
        this.error = error.response ? error.response.data.error : 'No response from server. Please try again later.';
      });
    },
    fetchProducts() {
      const token = localStorage.getItem('jwt');
      axios.get('https://dashboard.ordmenpodcast.com/v/list_webinars.php', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        this.products = response.data.webinars;
      })
      .catch(error => {
        console.error(error);
      });
    },
    toggleSort(key) {
    if (this.sortKey === key) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortKey = key;
      this.sortOrder = 'asc';
    }
    this.filteredTransactions.sort((a, b) => {
      let comparison = 0;
      if (a[key] > b[key]) {
        comparison = 1;
      } else if (a[key] < b[key]) {
        comparison = -1;
      }
      return this.sortOrder === 'asc' ? comparison : -comparison;
    });
  },
  getSortIcon(key) {
    if (this.sortKey === key) {
      return this.sortOrder === 'asc' ? 'icon-tabler-arrow-up' : 'icon-tabler-arrow-down';
    }
    return 'icon-tabler-arrow-up'; // default icon
  },

    applyFilters() {
      this.filteredTransactions = this.transactions.filter(transaction => {
        let match = true;
        const search = this.filter.search.toLowerCase();
        if (search && (!transaction.email.toLowerCase().includes(search) && !transaction.name.toLowerCase().includes(search))) {
          match = false;
        }
        if (this.filter.product && transaction.product_id !== this.filter.product) {
          match = false;
        }
        if (this.filter.product_name && (!transaction.product_name || !transaction.product_name.includes(this.filter.product_name))) {
          match = false;
        }
        if (this.filter.mentorship !== '' && transaction.mentorship !== this.filter.mentorship) {
          match = false;
        }
        if (this.filter.paid !== '' && transaction.paid !== this.filter.paid) {
          match = false;
        }
        if (this.filter.walletCharge !== '' && transaction.walletCharge !== this.filter.walletCharge) {
          match = false;
        }
        if (this.filter.abroad !== '' && transaction.abroad !== this.filter.abroad) {
          match = false;
        }
        if (this.filter.drop_tr !== '' && transaction.drop_tr !== this.filter.drop_tr) {
          match = false;
        }
        if (this.filter.start_time && new Date(transaction.created_at.split(' - ')[1]) < new Date(this.filter.start_time.replace(/\//g, '-'))) {
          match = false;
        }
        if (this.filter.end_time && new Date(transaction.created_at.split(' - ')[1]) > new Date(this.filter.end_time.replace(/\//g, '-'))) {
          match = false;
        }
        if (this.filter.nth_time && transaction.nth_time !== this.filter.nth_time) {
          match = false;
        }
        return match;
      });

    if (this.sortColumn) {
      this.filteredTransactions.sort((a, b) => {
        let modifier = this.sortDirection === 'asc' ? 1 : -1;
        if (this.sortColumn === 'amount') {
          return modifier * (parseFloat(a.amount) - parseFloat(b.amount));
        } else if (this.sortColumn === 'created_at') {
          return modifier * (new Date(a.created_at.split(' - ')[1]) - new Date(b.created_at.split(' - ')[1]));
        }
      });
    }

    },
    resetFilters() {
      this.filter.email = '';
      this.filter.name = '';
      this.filter.product = '';
      this.filter.product_name = '';
      this.filter.mentorship = '';
      this.filter.paid = '';
      this.filter.walletCharge = '';
      this.filter.abroad = '';
      this.filter.drop_tr = '';
      this.filter.start_time = '';
      this.filter.end_time = '';
      this.fetchTransactions();
    },

    showUserTransactions(email) {
      const token = localStorage.getItem('jwt');
      console.log('Fetching transactions for email:', email);

      axios.post('https://dashboard.ordmenpodcast.com/v/list_user_transactions.php', {
        email: email
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('User transactions fetched:', response.data);
        this.userTransactions = response.data;
        this.showUserTransactionsModal();
      })
      .catch(error => {
        console.error('Error fetching user transactions:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
      });
    },
    showUserTransactionsModal() {
      const modalElement = document.getElementById('userTransactionsModal');
      this.userTransactionsModalInstance = new Modal(modalElement);
      this.userTransactionsModalInstance.show();
    },
    hideUserTransactionsModal() {
      if (this.userTransactionsModalInstance) {
        this.userTransactionsModalInstance.hide();
      }
    },
    reloadData() {
      this.fetchTransactions();
    },
    getYesNo(value) {
      return value === '1' ? 'بله' : 'خیر';
    },
    euroOrTomans(value) {
      return value === '1' ? 'یورو' : 'تومان';
    },
    downloadCSV() {
      const filename = 'transactions.csv';
      const csv = this.convertToCSV(this.filteredTransactions);
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'text/csv;charset=utf-8;' });

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(transactions) {
      const columnMap = {
        'شناسه': 'id',
        'ایمیل': 'email',
        'نام': 'name',
        'شناسه محصول': 'product_id',
        'نام محصول': 'product_name',
        'مقدار': 'amount',
        'پرداخت شده': 'paid',
        'مشاوره': 'mentorship',
        'شارژ کیف پول': 'walletCharge',
        'خارجی': 'abroad',
        'حذف شده؟': 'drop_tr',
        'ایجاد شده در': 'created_at'
      };

      const header = Object.keys(columnMap).join(',');
      const rows = transactions.map(transaction => {
        const rowValues = Object.keys(columnMap).map(column => {
          const key = columnMap[column];
          if (key === 'amount') {
            return `${transaction.amount} ${this.euroOrTomans(transaction.abroad)}`;
          } else if (key === 'paid' || key === 'mentorship' || key === 'walletCharge' || key === 'abroad' || key === 'drop_tr') {
            return this.getYesNo(transaction[key]);
          } else {
            return transaction[key];
          }
        });
        return rowValues.join(',');
      });
      return `${header}\n${rows.join('\n')}`;
    },
    openModal() {
      const modal = new Modal(document.getElementById('transactionModal'));
      modal.show();
    },
    submitTransaction() {
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/add_transaction.php', this.newTransaction, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        this.reloadData();
        this.newTransaction = {
          email: '',
          name: '',
          product_id: '',
          amount: '',
          paid: '',
          mentorship: '',
          walletCharge: '',
          abroad: '',
          drop_tr: ''
        };
        const modal = Modal.getInstance(document.getElementById('transactionModal'));
        modal.hide();
      })
      .catch(error => {
        this.error = error.response ? error.response.data.error : 'No response from server. Please try again later.';
      });
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    changePageSize() {
      this.currentPage = 1;
    }
  }
};
</script>

<style scoped>
/* Add custom styles if needed */
.page-item{
  padding:0 5px;
}

.container{
    width: 100% !important;
    max-width: 100% !important;
    font-size:12.5px!important;
  }

.icon-tabler-chevron-up,
.icon-tabler-chevron-down {
  transition: transform 0.2s ease;
}

.icon-tabler-chevron-up {
  transform: rotate(0deg);
}

.icon-tabler-chevron-down {
  transform: rotate(180deg);
}


.centeredtext{
  text-align:center;
}
</style>
