<template>
  <div class="container mt-5">
    <div class="row">


        <div class="col-lg-12" style="margin-bottom:25px;">
          <div class="card" style="padding:10px 15px;border-radius:50px;">
            <div style="float:right;width:100%;">
            <div style="float:right;width:40%">
            


    <div v-if="showAttendeesTable">

    <button v-if="!showWebinarTable" style="float:Right;" class="btn btn-primaryy" @click="showWebinarTable = true; showAttendeesTable = false;">
      بازگشت به لیست محصولات
    </button>

</div>

            </div>


            <div style="float:left;width:20%">


            <div v-if="showWebinarTable">
<input
              type="text"
              v-model="searchQuery"
              placeholder="جستجو: نام محصول"
              class="form-control form-search"
              @input="filterWebinars"
            />
 </div>


    <div v-if="showAttendeesTable">

            <input
              type="text"
              v-model="searchQuery"
              placeholder="جستجو: نام، ایمیل، شماره همراه"
              class="form-control form-search"
              @input="filterAttendees"
            />
            </div>



              </div>
              </div>
          </div>
        </div>




      <div v-if="showWebinarTable" class="col-md-9 card" style="padding-top:17px;margin-bottom:15px;">

        <div class="table-responsive" style="width: 100%; table-layout: auto; max-height: 650px; overflow-y: auto;">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">ردیف</th>
                <th scope="col">نام محصول</th>
                <th scope="col">مبلغ تومانی</th>
                <th scope="col">مبلغ یورویی</th>
                <th scope="col">تعداد خریداران</th>
                <th scope="col">خریداران</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(group, groupIndex) in groupedWebinars" :key="group.title">
                <tr>
                  <td>{{ groupIndex + 1 }}</td>
<td v-if="group.webinars.length > 2" @click="toggleAccordion(group.title)" class="clickable">
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-outline icon-tabler-circle-arrow-down">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"/>
      <path d="M8 12l4 4"/>
      <path d="M12 8v8"/>
      <path d="M16 12l-4 4"/>
    </svg>
  </span>
  <span>{{ group.title }}</span>
</td>
<td v-else>
  {{ group.title }}
</td>


                  <td>{{ formatNum(group.latest.hazine) }}</td>
                  <td>{{ formatNum(group.latest.euro_hazine) }}</td>
                  
                  <td>{{ group.totalAttendees }}</td>
                  <td>
                    <button class="btn btn-primaryy" @click.stop="showAttendees(group.latest.id)">
                      نمایش خریداران
                    </button>
                  </td>
                </tr>
                <template v-if="isAccordionOpen(group.title)">
                  <tr v-for="(webinar, index) in group.webinars.slice(0, -1)" :key="webinar.id" class="accordion-row">
                    <td>{{ groupIndex + 1 }}.{{ index + 1 }}</td>
                    <td>{{ webinar.title }}</td>
                    <td>{{ formatNum(webinar.hazine) }}</td>
                    <td>{{ formatNum(webinar.euro_hazine) }}</td>
                    <td>{{ webinar.nth_course }}</td>
                    <td>
                      <span v-if="!webinar.event_start_date">وارد نشده</span>
                      <span v-else>
                        {{ formatDate(webinar.event_start_date) }}
                        <span v-if="webinar.event_end_date">
                          لغایت {{ formatDate(webinar.event_end_date) }}
                        </span>
                      </span>
                    </td>
                    <td>{{ webinar.paid_attendees_count }}</td>
                    <td>
                      <button class="btn btn-primaryy" @click.stop="showAttendees(webinar.id)">
                        نمایش خریداران
                      </button>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-md-3" v-if="showWebinarTable">
        <div class="card mt-4 mt-md-0">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a href="#tabs-stats" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">آمار</a>
              </li>
              <li class="nav-item" role="presentation"> <!-- Add this line to add the new tab -->
                <a href="#tabs-monthly-stats" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">آمار ماه</a>
              </li>

              <li class="nav-item" role="presentation">
                <a href="#tabs-filters" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">فیلترها</a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content">
              
              <div class="tab-pane fade active show" id="tabs-stats" role="tabpanel">
                <h4>آمار محصولات</h4>
                <div class="mt-4">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>ویژگی</th>
                        <th class="centeredtext">مبلغ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>تعداد خریداران</td>
                        <td class="centeredtext">{{ totalAttendees }}</td>
                      </tr>
                      <tr>
                        <td>تعداد خریداران منحصربه‌فرد</td>
                        <td class="centeredtext">{{ uniqueAttendeesCount }}</td>
                      </tr>
                      <tr>
                        <td>درآمد تومانی</td>
                        <td class="centeredtext">{{ formatNum(totalTomanIncome) }}</td>
                      </tr>
                      <tr>
                        <td>درآمد یورویی</td>
                        <td class="centeredtext">{{ formatNum(totalEuroIncome) }}</td>
                      </tr>
                      <tr>
                        <td>محصول با بیشترین خریدار</td>
                        <td class="centeredtext">{{ maxAttendeesWebinar }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <div class="tab-pane fade" id="tabs-monthly-stats" role="tabpanel"> <!-- Add this block for the new tab content -->
                <h4>آمار ماهانه محصولات</h4>
                <div class="mb-3">
                  <label for="monthSelect" class="form-label">انتخاب ماه:</label>
                  <select v-model="selectedMonth" @change="updateMonthlyStats" id="monthSelect" class="form-select">
                    <option value="01">فروردین</option>
                    <option value="02">اردیبهشت</option>
                    <option value="03">خرداد</option>
                    <option value="04">تیر</option>
                    <option value="05">مرداد</option>
                    <option value="06">شهریور</option>
                    <option value="07">مهر</option>
                    <option value="08">آبان</option>
                    <option value="09">آذر</option>
                    <option value="10">دی</option>
                    <option value="11">بهمن</option>
                    <option value="12">اسفند</option>
                  </select>
                </div>
                <div class="mt-4">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>ویژگی</th>
                        <th class="centeredtext">مبلغ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>تعداد خریداران</td>
                        <td class="centeredtext">{{ monthlyTotalAttendees }}</td>
                      </tr>
                      <tr>
                        <td>تعداد خریداران منحصربه‌فرد</td>
                        <td class="centeredtext">{{ monthlyUniqueAttendeesCount }}</td>
                      </tr>
                      <tr>
                        <td>درآمد تومانی</td>
                        <td class="centeredtext">{{ formatNum(monthlyTotalTomanIncome) }}</td>
                      </tr>
                      <tr>
                        <td>درآمد یورویی</td>
                        <td class="centeredtext">{{ formatNum(monthlyTotalEuroIncome) }}</td>
                      </tr>
                      <tr>
                        <td>محصول با بیشترین خریدار</td>
                        <td class="centeredtext">{{ monthlyMaxAttendeesWebinar }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              

              <div class="tab-pane fade" id="tabs-filters" role="tabpanel">
                <h4>فیلترها</h4>
                <div class="mt-4">
                  

                  <label for="location" class="form-label">موقعیت</label>
                  <select v-model="filter.location" id="location" class="form-select mb-2">
                    <option value="">همه</option>
                    <option value="domestic">داخلی</option>
                    <option value="international">خارجی</option>
                    <option value="both">هردو</option>
                  </select>

                  <div class="d-flex justify-content-between mb-2">
                    <button @click="resetFilters" class="btn btn-secondary">حذف فیلترها</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

        <!-- Add this button to go back to the webinar search and table -->
    


    
    
    <div v-if="showAttendeesTable" class="row mt-3">
      <div class="col-md-12">
        <div v-if="attendees.length > 0" class="table-responsive mt-3">
          <h2>خریداران محصول {{ submittedWebinarTitle }}</h2>

            <div v-if="selectedAttendees.length > 0" style="margin-bottom:10px;">
         
          <button
            v-if="selectedAttendees.length > 0"
            @click="showCustomEmailModal"
            class="btn btn-secondary mt-3"
            style="margin-right: 5px;"
          >
            ارسال ایمیل دلخواه
          </button>
          <button
            v-if="selectedAttendees.length > 0"
            @click="showCustomTelegramModal"
            class="btn btn-secondary mt-3"
            style="margin-right: 5px;"
          >
            ارسال پیام تلگرام دلخواه
          </button>
          </div>


          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  <input type="checkbox" @change="selectAll($event)" />
                </th>
                <th scope="col">نام</th>
                <th scope="col">ایمیل</th>
                <th scope="col">شماره همراه</th>
                <th scope="col">مبلغ</th>
                <th scope="col">واحد</th>
                <th scope="col">تاریخ</th>
                <th scope="col">ساعت</th>
                <th scope="col">اطلاعات کاربر</th>
                <th scope="col">تراکنش‌های کاربر</th>
                <th scope="col" v-if="selectedAttendees.length > 0">عملیات</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="attendee in filteredAttendees" :key="attendee.id">
                <td>
                  <input
                    type="checkbox"
                    v-model="selectedAttendees"
                    :value="attendee"
                  />
                </td>
                <td
                  @click="copyToClipboard(attendee.name)"
                  style="cursor: pointer;"
                >
                  {{ attendee.name }}
                </td>
                <td
                  @click="copyToClipboard(attendee.email)"
                  style="cursor: pointer;"
                >
                  {{ attendee.email }}
                </td>
                <td
                  @click="copyToClipboard(formatPhoneNumber(attendee.phone_number))"
                  style="cursor: pointer;"
                >
                  {{ formatPhoneNumber(attendee.phone_number) }}
                </td>
                <td
                  @click="copyToClipboard(attendee.amount)"
                  style="cursor: pointer;"
                >
                  {{ attendee.amount }}
                </td>
                <td
                  @click="copyToClipboard(euroOrTomans(attendee.abroad))"
                  style="cursor: pointer;"
                >
                  {{ euroOrTomans(attendee.abroad) }}
                </td>
                <td
                  style="font-size: 11px;"
                  @click="copyToClipboard(splitCreatedAt(attendee.created_at, 1))"
                >
                  {{ splitCreatedAt(attendee.created_at, 1) }}
                </td>
                <td
                  @click="copyToClipboard(splitCreatedAt(attendee.created_at, 0))"
                >
                  {{ splitCreatedAt(attendee.created_at, 0) }}
                </td>
                <td @click="userInfo(attendee.email)" style="cursor: pointer;">
                  نمایش اطلاعات
                </td>
                <td>
                  <button
                    class="btn btn-info btn-sm"
                    @click="showUserTransactions(attendee.email)"
                  >
                    نمایش تراکنش‌ها
                  </button>
                </td>
                <td v-if="selectedAttendees.length > 0">
                  {{ attendee.emailStatus || "" }}
                </td>
              </tr>
            </tbody>

            <div v-if="selectedAttendees.length > 0" style="margin-bottom:10px;">
         
         <button
           v-if="selectedAttendees.length > 0"
           @click="showCustomEmailModal"
           class="btn btn-secondary mt-3"
           style="margin-right: 5px;"
         >
           ارسال ایمیل دلخواه
         </button>
         <button
           v-if="selectedAttendees.length > 0"
           @click="showCustomTelegramModal"
           class="btn btn-secondary mt-3"
           style="margin-right: 5px;"
         >
           ارسال پیام تلگرام دلخواه
         </button>
         </div>
         </table>


        </div>
        <div v-if="error" class="alert alert-danger mt-3">
          {{ error }}
        </div>
      </div>
    </div>


    <!-- Modal -->
    <div class="modal fade" id="generateLicenceModal" tabindex="-1" role="dialog" aria-labelledby="generateLicenceModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="generateLicenceModalLabel">انتخاب محصول</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="productSelect">انتخاب محصول</label>
              <select v-model="selectedProductHash" class="form-control" id="productSelect" required>
                <option v-for="product in products" :key="product.hash" :value="product.hash">
                  {{ product.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="generateLicences">دریافت لایسنس</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Google Meet Link Modal -->
<div class="modal fade" id="googleMeetLinkModal" tabindex="-1" role="dialog" aria-labelledby="googleMeetLinkModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="googleMeetLinkModalLabel">لینک گوگل میت</h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="googleMeetLinkInput">لطفا لینک گوگل میت را وارد کنید</label>
          <input v-model="googleMeetLink" type="text" class="form-control" id="googleMeetLinkInput" required />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="hideGoogleMeetLinkModal">انصراف</button>
        <button type="button" class="btn btn-primary" @click="confirmSendWithLink">تایید</button>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation Modal -->
<div class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmationModalLabel">تایید ارسال</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>آیا برای ارسال مطمئن هستید؟</p>
        <div v-html="editorContent"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">لغو</button>
        <button type="button" class="btn btn-primary" @click="executeSend">ارسال</button>
      </div>
    </div>
  </div>
</div>


    <!-- Custom Email Modal -->
    <div class="modal fade" id="customEmailModal" tabindex="-1" role="dialog" aria-labelledby="customEmailModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customEmailModalLabel">ارسال ایمیل دلخواه به خریداران</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="customEmailSubject">موضوع</label>
              <input v-model="customEmailSubject" type="text" class="form-control" id="customEmailSubject" required />
            </div>
            <div class="form-group">
              <label for="customEmailContent">محتوا</label>
              <ckeditor :editor="editor" v-model="customEmailContent" :config="editorConfig"></ckeditor>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideCustomEmailModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendCustomEmails">ارسال ایمیل</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Custom Telegram Modal -->
    <div class="modal fade" id="customTelegramModal" tabindex="-1" role="dialog" aria-labelledby="customTelegramModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customTelegramModalLabel">ارسال پیام تلگرام دلخواه به خریداران</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="customTelegramContent">محتوا</label>
              <ckeditor :editor="editor" v-model="customTelegramContent" :config="editorConfig"></ckeditor>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideCustomTelegramModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendCustomTelegramMessages">ارسال پیام</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Invite Email Modal -->
    <div class="modal fade" id="inviteEmailModal" tabindex="-1" role="dialog" aria-labelledby="inviteEmailModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="inviteEmailModalLabel">ارسال ایمیل دعوت به وبینار</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="googleMeetLink">لینک گوگل میت</label>
              <input v-model="googleMeetLink" type="text" class="form-control" id="googleMeetLink" required />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideInviteEmailModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendInviteEmails">ارسال ایمیل</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Invite Telegram Modal -->
    <div class="modal fade" id="inviteTelegramModal" tabindex="-1" role="dialog" aria-labelledby="inviteTelegramModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="inviteTelegramModalLabel">ارسال پیام دعوت به تلگرام خریداران</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="googleMeetLink">لینک گوگل میت</label>
              <input v-model="googleMeetLink" type="text" class="form-control" id="googleMeetLink" required />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideInviteTelegramModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendInviteTelegramMessages">ارسال پیام</button>
          </div>
        </div>
      </div>
    </div>

    <!-- User Info Modal -->
    <div class="modal fade" id="userInfoModal" tabindex="-1" role="dialog" aria-labelledby="userInfoModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="userInfoModalLabel">اطلاعات کاربر</h5>
          </div>
          <div class="modal-body">
            <div v-if="userInfoData">
              <center><b>اطلاعات دریافتی از داشبورد ربات:</b></center>
              <p><strong>نام:</strong> {{ userInfoData.FirstNameLastName }}</p>
              <p><strong>ایمیل:</strong> {{ userInfoData.EMailAddress }}</p>
              <p><strong>ایمیل تایید شده:</strong> {{ userInfoData.VerifiedEmail }}</p>
              <p><strong>شماره همراه:</strong> {{ userInfoData.MobileNumber }}</p>
              <p><strong>شغل:</strong> {{ userInfoData.CurrentJob }}</p>
              <p><strong>رشته تحصیلی:</strong> {{ userInfoData.StudyField }}</p>
              <p><strong>مقطع تحصیلی:</strong> {{ userInfoData.EducationLevel }}</p>
              <p><strong>کشور/استان:</strong> {{ userInfoData.CountryStateResidence }}</p>
              <p><strong>کیف پول ایران:</strong> {{ userInfoData.IranWallet }}</p>
              <p><strong>کیف پول یورو:</strong> {{ userInfoData.EuroWallet }}</p>
              <center><b>اطلاعات دریافتی از داشبورد وب‌سایت:</b></center>
              <p><strong>نام:</strong> {{ userInfoData.name }}</p>
              <p><strong>رشته:</strong> {{ userInfoData.major }}</p>
              <p><strong>شغل:</strong> {{ userInfoData.job }}</p>
              <p><strong>شماره تلفن:</strong> {{ userInfoData.phoneNumber }}</p>
              <p><strong>ایمیل تایید شده:</strong> {{ getYesNo(userInfoData.verified) }}</p>
            </div>
            <div v-else>
              <p>اطلاعاتی یافت نشد</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideUserInfoModal">بستن</button>
          </div>
        </div>
      </div>
    </div>

    
<!-- User Transactions Modal -->
<div class="modal fade" id="userTransactionsModal" tabindex="-1" role="dialog" aria-labelledby="userTransactionsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="userTransactionsModalLabel">تراکنش‌های کاربر</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="overflow-y: auto; max-height: 400px;">
        <div v-if="userTransactions.length > 0" class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">محصول</th>
                <th scope="col">مقدار</th>
                <th scope="col">واحد</th>
                <th scope="col">تاریخ</th>
                <th scope="col">ساعت</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="transaction in userTransactions" :key="transaction.id">
                <td>{{ transaction.product_name }}</td>
                <td>{{ transaction.amount }}</td>
                <td>{{ euroOrTomans(transaction.abroad) }}</td>
                <td>{{ splitCreatedAt(transaction.created_at, 1) }}</td>
                <td>{{ splitCreatedAt(transaction.created_at, 0) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>تراکنشی یافت نشد</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
      </div>
    </div>
  </div>
</div>



    <!-- Toast -->
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="copyToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">عملیات</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>




  </div>
</template>


<script>
import axios from 'axios';
import { Modal, Toast } from 'bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { htmlToText } from 'html-to-text';
import jalaali from 'jalaali-js';

export default {
  data() {
    return {
      webinars: [],
      filteredWebinars: [],
      searchQuery: '',
      offlineFilter: false,
      selectedWebinarId: '',
    sortOrder: 'asc',
    sortKey: 'event_start_date',
      submittedWebinarTitle: null,
      attendees: [],
      selectedAttendees: [],
      products: [],
      selectedProductHash: '',
      error: '',
      editingAttendeeId: null,
      newLicence: '',
      modalInstance: null,
      toastInstance: null,
      toastMessage: '',
      customEmailSubject: '',
      customEmailContent: '',
      customTelegramContent: '',
      userInfoData: null,
      userInfoModalInstance: null,
      userTransactions: [],
      userTransactionsModalInstance: null,
      customTelegramInstance: null,
      googleMeetLink: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'ar',
        contentsLangDirection: 'rtl',
        alignment: {
          options: ['right', 'left']
        },
        toolbar: {
          items: [
            'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
            'insertTable', 'mediaEmbed', 'undo', 'redo'
          ]
        },
        link: {
          addTargetToExternalLinks: true
        }
      },
      confirmationModalInstance: null,
      editorContent: '',
      sendMethod: null,
      sendType: null,
      showWebinarTable: true,
      showAttendeesTable: false,
      accordionOpen: {},

      selectedMonth: '', // For month selection
      monthlyTotalAttendees: 0, // For monthly stats
      monthlyUniqueAttendeesCount: 0, // For monthly stats
      monthlyTotalTomanIncome: 0, // For monthly stats
      monthlyTotalEuroIncome: 0, // For monthly stats
      monthlyMaxAttendeesWebinar: '', // For monthly stats
      filters: {
        name: '',
        email: '',
        phone_number: '',
        chatID: '',
        product_id: '',
        product_name: '',
        amount: '',
      },
      filter: {
        nth_course: '',
        date_range: '',
        kind: '',
        location: ''
      }
    };
  },
  computed: {
    filteredAttendees() {
      return this.attendees.filter(attendee => {
        const matchesQuery = (!this.searchQuery || attendee.name.includes(this.searchQuery) || attendee.email.includes(this.searchQuery) || attendee.phone_number.includes(this.searchQuery));
        const matchesOffline = (!this.offlineFilter || attendee.offline == 1);
        return matchesQuery && matchesOffline;
      });
    },groupedWebinars() {
      const grouped = this.filteredWebinars.reduce((acc, webinar) => {
        if (!acc[webinar.title]) {
          acc[webinar.title] = { title: webinar.title, webinars: [] };
        }
        acc[webinar.title].webinars.push(webinar);
        return acc;
      }, {});

      for (let title in grouped) {
        grouped[title].webinars.sort((a, b) => this.compareNthCourse(a.nth_course, b.nth_course));
        grouped[title].latest = grouped[title].webinars[grouped[title].webinars.length - 1];
        grouped[title].totalAttendees = grouped[title].webinars.reduce((sum, webinar) => sum + parseInt(webinar.paid_attendees_count, 10), 0);

      }

      return Object.values(grouped).map(group => {
        group.webinars.push(group.latest);
        return group;
      });
    },

    nthCourses() {
      return [...new Set(this.webinars.map(webinar => webinar.nth_course))];
    },
    sortedNthCourses() {
      return this.getSortedNthCourses();
    },
    totalAttendees() {
      return this.groupedWebinars.reduce((sum, group) => {
        return sum + parseInt(group.latest.paid_attendees_count, 10);
      }, 0);
    },

    uniqueAttendeesCount() {
      const uniqueEmails = new Set();
      this.groupedWebinars.forEach(group => {
        group.latest.attendants.split(', ').forEach(email => {
          uniqueEmails.add(email);
        });
      });
      return uniqueEmails.size;
    },

    totalTomanIncome() {
      return this.groupedWebinars.reduce((sum, group) => {
        return sum + parseFloat(group.latest.sum_of_toman);
      }, 0).toFixed(2);
    },
    totalEuroIncome() {
      return this.groupedWebinars.reduce((sum, group) => {
        return sum + parseFloat(group.latest.sum_of_euro);
      }, 0).toFixed(2);
    },
    maxAttendeesWebinar() {
      let maxAttendees = 0;
      let webinarTitle = '';

      this.groupedWebinars.forEach(group => {
        const totalAttendees = group.webinars.reduce((sum, webinar) => {
          return sum + parseInt(webinar.paid_attendees_count, 10);
        }, 0);

        if (totalAttendees > maxAttendees) {
          maxAttendees = totalAttendees;
          webinarTitle = group.title;
        }
      });

      return webinarTitle;
    }

  },
  watch: {
    searchQuery: 'filterWebinars',
    'filter.nth_course': 'filterWebinars',
    'filter.date_range': 'filterWebinars',
    'filter.kind': 'filterWebinars',
    'filter.location': 'filterWebinars'
  },
  methods: {
  setDefaultMonth() {
    const now = new Date();
    const { jm } = jalaali.toJalaali(now);
    const month = jm.toString().padStart(2, '0');
    this.selectedMonth = month;
    this.updateMonthlyStats();
  },

  updateMonthlyStats() { // Add this method
    const selectedMonth = this.selectedMonth;
    const monthlyWebinars = this.webinars.filter(webinar => webinar.event_start_date.split('-')[1] === selectedMonth);

    this.monthlyTotalAttendees = monthlyWebinars.reduce((sum, webinar) => {
      return sum + parseInt(webinar.paid_attendees_count, 10);
    }, 0);

    const uniqueEmails = new Set();
    monthlyWebinars.forEach(webinar => {
      webinar.attendants.split(', ').forEach(email => {
        uniqueEmails.add(email);
      });
    });
    this.monthlyUniqueAttendeesCount = uniqueEmails.size;

    this.monthlyTotalTomanIncome = monthlyWebinars.reduce((sum, webinar) => {
      return sum + parseFloat(webinar.sum_of_toman);
    }, 0).toFixed(2);

    this.monthlyTotalEuroIncome = monthlyWebinars.reduce((sum, webinar) => {
      return sum + parseFloat(webinar.sum_of_euro);
    }, 0).toFixed(2);

    let maxAttendees = 0;
    let webinarTitle = '';
    monthlyWebinars.forEach(webinar => {
      const totalAttendees = parseInt(webinar.paid_attendees_count, 10);
      if (totalAttendees > maxAttendees) {
        maxAttendees = totalAttendees;
        webinarTitle = webinar.title;
      }
    });
    this.monthlyMaxAttendeesWebinar = webinarTitle;
  },
    
    sortData(key) {
    if (this.sortKey === key) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortKey = key;
      this.sortOrder = 'asc';
    }
    this.filteredWebinars.sort((a, b) => {
      let comparison = 0;
      if (a[key] > b[key]) {
        comparison = 1;
      } else if (a[key] < b[key]) {
        comparison = -1;
      }
      return this.sortOrder === 'asc' ? comparison : -comparison;
    });
  },

    formatNum(number) {
      // Convert the float to an integer
      let intNumber = Math.floor(number);

      // Convert the number to a string and add commas
      let formattedNumber = intNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "،");

      return formattedNumber;
    },

    formatDate(dateString) {
      const shamsiMonths = [
        'فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور',
        'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'
      ];
      const [year, month, day] = dateString.split('-');
      const monthName = shamsiMonths[parseInt(month) - 1];
      return `${day} ${monthName} ${year}`;
    },
    fetchWebinars() {
      const token = localStorage.getItem('jwt');
      axios.get('https://dashboard.ordmenpodcast.com/v/list_products_w_m.php', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          this.webinars = response.data.webinars;
          this.filteredWebinars = this.webinars;
        this.setDefaultMonth(); // Ensure month is set after fetching webinars
        this.updateMonthlyStats(); // Update stats after setting the month
        })
        .catch(error => {
          this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
        });
    },
    splitCreatedAt(dateString, i) {
      if (dateString) {
        return dateString.split(' - ')[i]; // Adjust the index [0] or [1] based on which part you need
      }
      return '';
    },
    getSortedNthCourses() {
      const order = ['اول', 'دوم', 'سوم', 'چهارم', 'پنجم', 'ششم', 'هفتم', 'هشتم', 'نهم', 'دهم', 'یازدهم', 'دوازدهم', 'سیزدهم', 'چهاردهم', 'پانزدهم'];
      return this.nthCourses.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    },
    compareNthCourse(a, b) {
      const order = ['اول', 'دوم', 'سوم', 'چهارم', 'پنجم', 'ششم', 'هفتم', 'هشتم', 'نهم', 'دهم', 'یازدهم', 'دوازدهم', 'سیزدهم', 'چهاردهم', 'پانزدهم'];
      return order.indexOf(a) - order.indexOf(b);
    },
    toggleAccordion(title) {
      this.accordionOpen = {
        ...this.accordionOpen,
        [title]: !this.accordionOpen[title]
      };
    },
    isAccordionOpen(title) {
      return !!this.accordionOpen[title];
    },
    filterWebinars() {
      let webinars = this.webinars;

      if (this.searchQuery) {
        webinars = webinars.filter(webinar =>
          webinar.title.includes(this.searchQuery)
        );
      }

      if (this.filter.nth_course) {
        webinars = webinars.filter(webinar =>
          webinar.nth_course === this.filter.nth_course
        );
      }

      if (this.filter.date_range) {
        const currentDate = new Date().toISOString().split('T')[0];
        if (this.filter.date_range === 'past') {
          webinars = webinars.filter(webinar =>
            webinar.event_end_date && webinar.event_end_date < currentDate
          );
        } else if (this.filter.date_range === 'future') {
          webinars = webinars.filter(webinar =>
            webinar.event_end_date && webinar.event_end_date >= currentDate
          );
        }
      }

      if (this.filter.kind) {
        webinars = webinars.filter(webinar =>
          this.filter.kind === 'course' ? webinar.is_course == 1 : webinar.is_course == 0
        );
      }

      if (this.filter.location) {
        webinars = webinars.filter(webinar => {
          if (this.filter.location === 'domestic') {
            return webinar.euro_hazine === 0 || !webinar.euro_hazine;
          } else if (this.filter.location === 'international') {
            return webinar.hazine === 0 || !webinar.hazine;
          } else if (this.filter.location === 'both') {
            return (webinar.hazine !== 0 && webinar.hazine) && (webinar.euro_hazine !== 0 && webinar.euro_hazine);
          }
        });
      }

      this.filteredWebinars = webinars;
    },

    showAttendees(webinarId) {
        this.selectedWebinarId = webinarId;
        this.attendees = []; // Reset attendees list
        this.error = ''; // Reset error message

        const token = localStorage.getItem('jwt');
        console.log('Fetching attendees for webinar:', this.selectedWebinarId);
        axios.get(`https://dashboard.ordmenpodcast.com/v/list_attendees.php?webinar_id=${this.selectedWebinarId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
        })
        .then(response => {
        console.log('Attendees fetched:', response.data);
        if (response.data.attendees) {
            this.attendees = response.data.attendees;
            this.submittedWebinarTitle = response.data.webinar_name;
            this.showWebinarTable = false;
            this.showAttendeesTable = true;
        } else {
            this.error = response.data.message || 'هیچ شرکت کننده‌ای یافت نشد';
        }
        })
        .catch(error => {
        console.error('Error fetching attendees:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
        });
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedAttendees = [...this.filteredAttendees];
      } else {
        this.selectedAttendees = [];
      }
    },
    showModal() {
      const firstAttendeeProductName = this.selectedAttendees[0].product_name;
      const token = localStorage.getItem('jwt');
      console.log('Fetching products for:', firstAttendeeProductName);
      axios.post('https://dashboard.ordmenpodcast.com/v/spot_products.php', {
        product_name: firstAttendeeProductName
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('Products fetched:', response.data);
        this.products = response.data.products;
        const modalElement = document.getElementById('generateLicenceModal');
        this.modalInstance = new Modal(modalElement);
        this.modalInstance.show();
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
      });
    },
    hideModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },
    showCustomEmailModal() {
      const modalElement = document.getElementById('customEmailModal');
      this.customEmailInstance = new Modal(modalElement);
      this.customEmailInstance.show();
    },
    hideCustomEmailModal() {
      if (this.customEmailInstance) {
        this.customEmailInstance.hide();
      }
    },
    generateLicences() {
      const token = localStorage.getItem('jwt');
      console.log('Generating licences for selected attendees...');
      const promises = this.selectedAttendees.map((attendee, index) => {
        console.log('Data being sent for attendee:', {
          name: attendee.name,
          email: attendee.email,
          hashmd5: attendee.hashmd5,
          hash: this.selectedProductHash
        });

        return new Promise((resolve) => setTimeout(resolve, index * 1500)).then(() => {
          return axios.post('https://dashboard.ordmenpodcast.com/v/generate_licence.php', {
            name: attendee.name,
            email: attendee.email,
            hashmd5: attendee.hashmd5,
            hash: this.selectedProductHash
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Licence generated for:', attendee, response.data);
            if (response.data.licence) {
              this.updateLicence(response.data.hashmd5, response.data.licence);
            } else {
              throw new Error(response.data.error || 'Failed to generate licence');
            }
          })
          .catch(error => {
            console.error('Error generating licence for:', attendee, error);
            throw error;
          });
        });
      });

      Promise.all(promises)
        .then(() => {
          console.log('All licences generated successfully.');
          this.toastMessage = 'لایسنس‌ها برای خریداران ایجاد و به آن‌ها افزوده شد.';
          this.showToast();
          this.hideModal();
        })
        .catch(error => {
          console.error('Error generating licences:', error);
          this.error = error.message || 'Failed to generate licences for selected attendees.';
        });
    },
    sendEmails() {
      const token = localStorage.getItem('jwt');
      console.log('Sending emails to selected attendees...');
      
      const promises = this.selectedAttendees.map((attendee, index) => {
        const emailContent = `
          <p style='text-align:right;direction:rtl;font-family:Tahoma;font-size:13px;'>
          ${attendee.name} عزیز سلام؛
          <br><br>
          برای دیدن ویدیوی ضبط‌شده وبینار «${this.submittedWebinarTitle}» لازم است نرم‌افزار اسپات پلیر (<a href="https://spotplayer.ir/">SpotPlayer</a>) را روی کامپیوتر یا تلفن همراه خود (ویندوز، مک، اندروید و یا آیفون) نصب کنید.
          <br><br>
          دقت داشته باشید اسپات‌پلیر حتما باید روی دستگاه شما نصب باشد. (ویدیو روی نسخه‌ی وب اسپات‌پلیر پخش نمی شود)
          <br><br>
          با وارد نمودن لایسنس زیر در برنامه به ویدیو دسترسی خواهید داشت:
          <br>
          </p>
          <code style="direction:ltr;text-align:left">
          ${attendee.licence}
          </code>
          <p style='text-align:right;direction:rtl;font-family:Tahoma;font-size:13px;'>
          <br>
          این لایسنس روی دو دستگاه (با سیستم عامل متفاوت) قابل استفاده است.<br>
          چنانچه دستگاه‌ خود را تغییر دهید یا به هر دلیلی لایسنس فعلی را از دست دهید، برای دریافت لایسنس جدید، نیاز به پرداخت هزینه است.<br><br>
          در صورت بروز مشکل یا عدم دسترسی به ویدیو، به 
          <a href="https://t.me/Ordmenism">آی‌دی ادمین</a>
          در تلگرام پیام دهید.
          <br><br>
          با سپاس، فاطمه گودرزی دستیار پادکست آدم‌های معمولی
          </p>
        `;
        
        // Set the email status to "در حال ارسال ایمیل..." before sending
        attendee.emailStatus = 'در حال ارسال ایمیل...';

        return new Promise((resolve) => setTimeout(resolve, index * 3000)).then(() => {
          return axios.post('https://dashboard.ordmenpodcast.com/v/send_email.php', {
            name: attendee.name,
            email: attendee.email,
            subject: `نحوه دسترسی به ویدیوی ضبط‌شده وبینار ${this.submittedWebinarTitle}`,
            content: emailContent
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Email sent to:', attendee, response.data);
            if (response.data.success) {
              // Update the email status to "ایمیل ارسال شد."
              attendee.emailStatus = 'ایمیل ارسال شد.';
            } else {
              throw new Error(response.data.error || 'Failed to send email');
            }
          })
          .catch(error => {
            console.error('Error sending email to:', attendee, error);
            // Update the email status to "عدم امکان ارسال ایمیل"
            attendee.emailStatus = 'عدم امکان ارسال ایمیل';
            throw error;
          });
        });
      });

      Promise.all(promises)
        .then(() => {
          console.log('All emails sent successfully.');
          this.toastMessage = 'ایمیل‌های لایسنس با موفقیت ارسال شدند.';
          this.showToast();
        })
        .catch(error => {
          console.error('Error sending emails:', error);
          this.error = error.message || 'Failed to send emails to selected attendees.';
        });
    },
    sendTelegramMessages() {
      const token = localStorage.getItem('jwt');
      console.log('Sending Telegram messages to selected attendees...');
      
      const promises = this.selectedAttendees.map((attendee, index) => {
        if (!attendee.chatID || attendee.chatID === "") {
          attendee.emailStatus = 'اکانت تلگرام وجود ندارد';
          return Promise.resolve();
        }

        const telegramMessage = `${attendee.name} عزیز سلام؛<br><br>
برای دیدن ویدیوی ضبط‌شده وبینار «${this.submittedWebinarTitle}» لازم است نرم‌افزار اسپات پلیر (<a href="https://spotplayer.ir/">SpotPlayer</a>) را روی کامپیوتر یا تلفن همراه خود (ویندوز، مک، اندروید و یا آیفون) نصب کنید. دقت داشته باشید اسپات‌پلیر حتما باید روی دستگاه شما نصب باشد. (ویدیو روی نسخه‌ی وب اسپات‌پلیر پخش نمی شود) با وارد نمودن لایسنس زیر در برنامه به ویدیو دسترسی خواهید داشت:<br><br>
<code>${attendee.licence}</code><br>این لایسنس روی دو دستگاه (با سیستم عامل متفاوت) قابل استفاده است.<br>چنانچه دستگاه‌ خود را تغییر دهید یا به هر دلیلی لایسنس فعلی را از دست دهید، برای دریافت لایسنس جدید، نیاز به پرداخت هزینه است.<br><br>در صورت بروز مشکل یا عدم دسترسی به ویدیو، به <a href="https://t.me/Ordmenism">آی‌دی ادمین</a> در تلگرام پیام دهید.<br><br>با سپاس، فاطمه گودرزی دستیار پادکست آدم‌های معمولی`;
        
        // Set the email status to "در حال ارسال پیام تلگرام..." before sending
        attendee.emailStatus = 'در حال ارسال پیام تلگرام...';

        return new Promise((resolve) => setTimeout(resolve, index * 1500)).then(() => {
          return axios.post('https://dashboard.ordmenpodcast.com/v/send_telegram_message.php', {
            chatID: attendee.chatID,
            text: telegramMessage
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Telegram message sent to:', attendee, response.data);
            if (response.data && response.data.success) {
              // Update the email status to "پیام تلگرامی ارسال شد."
              attendee.emailStatus = 'پیام تلگرامی ارسال شد.';
            } else {
              console.error('Telegram API error:', response.data);
              attendee.emailStatus = 'عدم امکان ارسال پیام تلگرامی به علت مشکلی از سمت تلگرام';
              throw new Error('عدم امکان ارسال پیام تلگرامی به دلیل مشکلی از سمت تلگرام');
            }
          })
          .catch(error => {
            console.error('Error sending Telegram message to:', attendee, error);
            // Update the email status to "عدم امکان ارسال پیام تلگرامی"
            attendee.emailStatus = 'عدم امکان ارسال پیام تلگرامی به علت مشکلی از سمت تلگرام';
            throw error;
          });
        });
      });

      Promise.all(promises)
        .then(() => {
          console.log('All Telegram messages sent successfully.');
          this.toastMessage = 'لایسنس‌ها به تلگرام خریداران ارسال شد.';
          this.showToast();
        })
        .catch(error => {
          console.error('Error sending Telegram messages:', error);
          this.error = error.message || 'Failed to send Telegram messages to selected attendees.';
        });
    },
    formatPhoneNumber(input) {
      // Remove decimal part by converting to an integer
      let integerPart = Math.floor(input);

      // Convert to string
      let stringPart = integerPart.toString();

      // Ensure the string starts with '0'
      if (stringPart[0] !== '0' && stringPart !== "NaN") {
        stringPart = '0' + stringPart;
      }

      return stringPart;
    },
    sendCustomEmails() {
      const token = localStorage.getItem('jwt');
      console.log('Sending custom emails to selected attendees...');
      
      const promises = this.selectedAttendees.map((attendee, index) => {
        const emailContent = `
          <div style='text-align:right!important;direction:rtl!important;font-family:Tahoma!important;font-size:13px;'>
          ${attendee.name} عزیز سلام؛
          <br><br>
          ${this.customEmailContent}
          </div>
        `;
        
        // Set the email status to "در حال ارسال ایمیل..." before sending
        attendee.emailStatus = 'در حال ارسال ایمیل...';

        return new Promise((resolve) => setTimeout(resolve, index * 3000)).then(() => {
          return axios.post('https://dashboard.ordmenpodcast.com/v/send_email.php', {
            name: attendee.name,
            email: attendee.email,
            subject: this.customEmailSubject,
            content: emailContent
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Email sent to:', attendee, response.data);
            if (response.data.success) {
              // Update the email status to "ایمیل ارسال شد."
              attendee.emailStatus = 'ایمیل ارسال شد.';
            } else {
              throw new Error(response.data.error || 'Failed to send email');
            }
          })
          .catch(error => {
            console.error('Error sending email to:', attendee, error);
            // Update the email status to "عدم امکان ارسال ایمیل"
            attendee.emailStatus = 'عدم امکان ارسال ایمیل';
            throw error;
          });
        });
      });

      Promise.all(promises)
        .then(() => {
          console.log('All custom emails sent successfully.');
          this.toastMessage = 'ایمیل‌های دلخواه با موفقیت ارسال شدند.';
          this.showToast();
          this.hideCustomEmailModal();
        })
        .catch(error => {
          console.error('Error sending custom emails:', error);
          this.error = error.message || 'Failed to send custom emails to selected attendees.';
        });
    },
    showCustomTelegramModal() {
      const modalElement = document.getElementById('customTelegramModal');
      this.customTelegramInstance = new Modal(modalElement);
      this.customTelegramInstance.show();
    },
    hideCustomTelegramModal() {
      if (this.customTelegramInstance) {
        this.customTelegramInstance.hide();
      }
    },
    sendCustomTelegramMessages() {
      const token = localStorage.getItem('jwt');
      console.log('Sending custom Telegram messages to selected attendees...');

      const promises = this.selectedAttendees.map((attendee, index) => {
        if (!attendee.chatID || attendee.chatID === "") {
          attendee.emailStatus = 'اکانت تلگرام وجود ندارد';
          return Promise.resolve();
        }

        const telegramMessage = `
          ${attendee.name} عزیز سلام؛\n
          ${htmlToText(this.customTelegramContent, {
            wordwrap: 130,
            uppercaseHeadings: false,
            singleNewLineParagraphs: true,
            tags: { // Optional custom tag handling
              'a': { format: 'inline' },
              'p': { format: 'block' }
            }
          })}
        `;

        attendee.emailStatus = 'در حال ارسال پیام تلگرام...';

        return new Promise((resolve) => setTimeout(resolve, index * 1500)).then(() => {
          return axios.post('https://dashboard.ordmenpodcast.com/v/send_telegram_message.php', {
            chatID: attendee.chatID,
            text: telegramMessage
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Telegram message sent to:', attendee, response.data);
            if (response.data.success) {
              attendee.emailStatus = 'پیام تلگرامی ارسال شد.';
            } else {
              console.error('Telegram API error:', response.data);
              attendee.emailStatus = 'عدم امکان ارسال پیام تلگرامی';
              throw new Error(response.data.error || 'Failed to send Telegram message');
            }
          })
          .catch(error => {
            console.error('Error sending Telegram message to:', attendee, error);
            attendee.emailStatus = 'عدم امکان ارسال پیام تلگرامی';
            throw error;
          });
        });
      });

      Promise.all(promises)
        .then(() => {
          console.log('All custom Telegram messages sent successfully.');
          this.toastMessage = 'پیام‌های تلگرام با موفقیت ارسال شدند.';
          this.showToast();
          this.hideCustomTelegramModal();
        })
        .catch(error => {
          console.error('Error sending custom Telegram messages:', error);
          this.error = error.message || 'Failed to send custom Telegram messages to selected attendees.';
        });
    },
    userInfo(email) {
      const token = localStorage.getItem('jwt');
      console.log('Fetching user info for email:', email);

      axios.post('https://dashboard.ordmenpodcast.com/v/get_user_info.php', {
        email: email
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('User info fetched:', response.data);
        this.userInfoData = response.data;
        this.showUserInfoModal();
      })
      .catch(error => {
        console.error('Error fetching user info:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
      });
    },
    showUserInfoModal() {
      const modalElement = document.getElementById('userInfoModal');
      this.userInfoModalInstance = new Modal(modalElement);
      this.userInfoModalInstance.show();
    },
    hideUserInfoModal() {
      if (this.userInfoModalInstance) {
        this.userInfoModalInstance.hide();
      }
    },
    showUserTransactions(email) {
      const token = localStorage.getItem('jwt');
      console.log('Fetching transactions for email:', email);

      axios.post('https://dashboard.ordmenpodcast.com/v/list_user_transactions.php', {
        email: email
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('User transactions fetched:', response.data);
        this.userTransactions = response.data;
        this.showUserTransactionsModal();
      })
      .catch(error => {
        console.error('Error fetching user transactions:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
      });
    },
    showUserTransactionsModal() {
      const modalElement = document.getElementById('userTransactionsModal');
      this.userTransactionsModalInstance = new Modal(modalElement);
      this.userTransactionsModalInstance.show();
    },
    hideUserTransactionsModal() {
      if (this.userTransactionsModalInstance) {
        this.userTransactionsModalInstance.hide();
      }
    },
    showInviteEmailModal() {
      const modalElement = document.getElementById('inviteEmailModal');
      this.inviteEmailInstance = new Modal(modalElement);
      this.inviteEmailInstance.show();
    },
    hideInviteEmailModal() {
      if (this.inviteEmailInstance) {
        this.inviteEmailInstance.hide();
      }
    },
    sendInviteEmails() {
      const token = localStorage.getItem('jwt');
      console.log('Sending invite emails to selected attendees...');

      const promises = this.selectedAttendees.map((attendee, index) => {
        const emailContent = `
          <p style='text-align:right;direction:rtl;font-family:Tahoma;font-size:13px;'>
          ${attendee.name} عزیز و گرامی درود؛<br><br>
          با سپاس از شما برای ثبت‌نام و شرکت در وبینار «${this.submittedWebinarTitle}»، لطفا برای حضور در وبینار به موارد زیر توجه داشته باشید:<br>
          ۱- از طریق این لینک به وبینار در <a href="${this.googleMeetLink}">گوگل میت</a> بپیوندید. شما قبلا به وبینار دعوت شده‌اید. توجه داشته باشید که باید با همان ایمیلی که در وبینار ثبت‌نام کردید وارد گوگل میت‌ شوید وگرنه اجازه ورود به شما داده نمی‌شود. <br>
          ۲- ترجیحا ده دقیقه قبل از شروع وبینار روی لینک باشید.<br>
          ۳- در زمان ورود لطفا ماکروفون‌تان را خاموش کنید. <br>
          ۴- در طول وبینار اگر پرسشی داشتید می‌تونید با بلند کردن دست و با تایید سخنران ماکروفن‌تان را باز کرده و سوال‌تان را مطرح کنید. یا اینکه می‌توانید سوال‌تان را در چت مطرح کنید تا سخنران به آن پاسخ دهد. اگر مایل بودید دوربین‌تان را هم می‌توانید روشن یا خاموش نگه دارید. <br>
          ۵- وبینار رکورد شده و پس از وبینار برای مشاهده دوباره در اختیارتان قرار خواهد گرفت. بنابراین برای حفظ حقوق معنوی پادکست آدمهای معمولی لطفا وبینار را رکورد نکنید. <br>
          ۶- برای هرگونه پرسش احتمالی و یا نیاز به هرگونه کمک در صورت مشکل داشتن در ورود به وبینار با پاسخ به همین ایمیل و یا با ارسال پیام به تلگرام ادمین پادکست تماس بگیرید. <br><br>
          مشتاقانه منتظر شروع وبینار و گفتگو با شما عزیزان هستیم.<br><br>
          با سپاس، فاطمه گودرزی دستیار پادکست آدم‌های معمولی
          </p>
        `;

        attendee.emailStatus = 'در حال ارسال ایمیل دعوت...';

        return new Promise((resolve) => setTimeout(resolve, index * 1500)).then(() => {
          return axios.post('https://dashboard.ordmenpodcast.com/v/send_email.php', {
            name: attendee.name,
            email: attendee.email,
            subject: `اطلاعات شرکت در وبینار ${this.submittedWebinarTitle}`,
            content: emailContent
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Invite email sent to:', attendee, response.data);
            if (response.data.success) {
              attendee.emailStatus = 'ایمیل دعوت ارسال شد.';
            } else {
              throw new Error(response.data.error || 'Failed to send invite email');
            }
          })
          .catch(error => {
            console.error('Error sending invite email to:', attendee, error);
            attendee.emailStatus = 'عدم امکان ارسال ایمیل دعوت';
            throw error;
          });
        });
      });

      Promise.all(promises)
        .then(() => {
          console.log('All invite emails sent successfully.');
          this.toastMessage = 'ایمیل‌های دعوت به وبینار با موفقیت ارسال شدند.';
          this.showToast();
          this.hideInviteEmailModal();
        })
        .catch(error => {
          console.error('Error sending invite emails:', error);
          this.error = error.message || 'Failed to send invite emails to selected attendees.';
        });
    },
    showInviteTelegramModal() {
      const modalElement = document.getElementById('inviteTelegramModal');
      this.inviteTelegramInstance = new Modal(modalElement);
      this.inviteTelegramInstance.show();
    },
    hideInviteTelegramModal() {
      if (this.inviteTelegramInstance) {
        this.inviteTelegramInstance.hide();
      }
    },
    sendInviteTelegramMessages() {
      const token = localStorage.getItem('jwt');
      console.log('Sending invite Telegram messages to selected attendees...');

      const promises = this.selectedAttendees.map((attendee, index) => {
        if (!attendee.chatID || attendee.chatID === "") {
          attendee.emailStatus = 'اکانت تلگرام وجود ندارد';
          return Promise.resolve();
        }

        const telegramMessage = `
          ${attendee.name} عزیز و گرامی درود؛
          با سپاس از شما برای ثبت‌نام و شرکت در وبینار «${this.submittedWebinarTitle}»، لطفا برای حضور در وبینار به موارد زیر توجه داشته باشید:
          ۱- از طریق این لینک به وبینار در <a href="${this.googleMeetLink}">گوگل میت</a> بپیوندید. شما قبلا به وبینار دعوت شده‌اید. توجه داشته باشید که باید با همان ایمیلی که در وبینار ثبت‌نام کردید وارد گوگل میت‌ شوید وگرنه اجازه ورود به شما داده نمی‌شود.
          ۲- ترجیحا ده دقیقه قبل از شروع وبینار روی لینک باشید.
          ۳- در زمان ورود لطفا ماکروفون‌تان را خاموش کنید.
          ۴- در طول وبینار اگر پرسشی داشتید می‌تونید با بلند کردن دست و با تایید سخنران ماکروفن‌تان را باز کرده و سوال‌تان را مطرح کنید. یا اینکه می‌توانید سوال‌تان را در چت مطرح کنید تا سخنران به آن پاسخ دهد. اگر مایل بودید دوربین‌تان را هم می‌توانید روشن یا خاموش نگه دارید.
          ۵- وبینار رکورد شده و پس از وبینار برای مشاهده دوباره در اختیارتان قرار خواهد گرفت. بنابراین برای حفظ حقوق معنوی پادکست آدمهای معمولی لطفا وبینار را رکورد نکنید.
          ۶- برای هرگونه پرسش احتمالی و یا نیاز به هرگونه کمک در صورت مشکل داشتن در ورود به وبینار با پاسخ به همین ایمیل و یا با ارسال پیام به تلگرام ادمین پادکست تماس بگیرید.
          مشتاقانه منتظر شروع وبینار و گفتگو با شما عزیزان هستیم.
          با سپاس، فاطمه گودرزی دستیار پادکست آدم‌های معمولی
        `;

        attendee.emailStatus = 'در حال ارسال پیام تلگرام...';

        return new Promise((resolve) => setTimeout(resolve, index * 1500)).then(() => {
          return axios.post('https://dashboard.ordmenpodcast.com/v/send_telegram_message.php', {
            chatID: attendee.chatID,
            text: telegramMessage
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Telegram message sent to:', attendee, response.data);
            if (response.data.success) {
              attendee.emailStatus = 'پیام تلگرامی ارسال شد.';
            } else {
              throw new Error(response.data.error || 'Failed to send Telegram message');
            }
          })
          .catch(error => {
            console.error('Error sending Telegram message to:', attendee, error);
            attendee.emailStatus = 'عدم امکان ارسال پیام تلگرامی';
            throw error;
          });
        });
      });

      Promise.all(promises)
        .then(() => {
          console.log('All invite Telegram messages sent successfully.');
          this.toastMessage = 'پیام‌های دعوت به وبینار به تلگرام خریداران ارسال شد.';
          this.showToast();
          this.hideInviteTelegramModal();
        })
        .catch(error => {
          console.error('Error sending invite Telegram messages:', error);
          this.error = error.message || 'Failed to send invite Telegram messages to selected attendees.';
        });
    },
    getYesNo(value) {
      return value === '1' ? 'بله' : 'خیر';
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Licence copied to clipboard:', text);
        this.toastMessage = 'به کلیپ‌بورد کپی شد!';
        this.showToast();
      }).catch(err => {
        console.error('Failed to copy licence:', err);
      });
    },
    showToast() {
      const toastElement = document.getElementById('copyToast');
      this.toastInstance = new Toast(toastElement);
      this.toastInstance.show();
    },
    editLicence(attendee) {
      this.editingAttendeeId = attendee.id;
      this.newLicence = attendee.licence;
    },
    cancelEdit() {
      this.editingAttendeeId = null;
      this.newLicence = '';
    },
    updateLicence(hashmd5, newLicence) {
      const token = localStorage.getItem('jwt');
      console.log('Updating licence for hashmd5:', hashmd5);
      axios.post('https://dashboard.ordmenpodcast.com/v/update_licence.php', {
        hashmd5: hashmd5,
        licence: newLicence
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('Licence updated:', response.data);
        if (response.data.success) {
          this.attendees = this.attendees.map(attendee => {
            if (attendee.hashmd5 === hashmd5) {
              attendee.licence = newLicence;
            }
            return attendee;
          });
          this.editingAttendeeId = null;
          this.newLicence = '';
          this.toastMessage = 'مقدار لایسنس تغییر پیدا کرد.';
          this.showToast();
        } else {
          this.error = response.data.error || 'Failed to update licence';
        }
      })
      .catch(error => {
        console.error('Error updating licence:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
      });
    },
    euroOrTomans(isAbroad) {
      if (isAbroad == 1){
        return "یورو";
      } else {
        return "تومان";
      }
    },
    
  confirmSend(type, method) {
    this.sendType = type;
    this.sendMethod = method;
    if (type === 'inviteEmail' || type === 'inviteTelegram') {
      this.showGoogleMeetLinkModal();
    } else {
      this.editorContent = this.generateMessagePreview(type);
      this.showConfirmationModal();
    }
  },
  showGoogleMeetLinkModal() {
    const modalElement = document.getElementById('googleMeetLinkModal');
    this.googleMeetLinkModalInstance = new Modal(modalElement);
    this.googleMeetLinkModalInstance.show();
  },
  hideGoogleMeetLinkModal() {
    if (this.googleMeetLinkModalInstance) {
      this.googleMeetLinkModalInstance.hide();
    }
  },
  confirmSendWithLink() {
    this.hideGoogleMeetLinkModal();
    this.editorContent = this.generateMessagePreview(this.sendType);
    this.showConfirmationModal();
  },
  showConfirmationModal() {
    const modalElement = document.getElementById('confirmationModal');
    this.confirmationModalInstance = new Modal(modalElement);
    this.confirmationModalInstance.show();
  },
  generateMessagePreview(type) {
    let content = '';
    switch (type) {
      case 'email':
        content = `
          <p style='text-align:right;direction:rtl;font-family:Tahoma;font-size:13px;'>
          ${this.selectedAttendees[0].name} عزیز سلام؛
          <br><br>
          برای دیدن ویدیوی ضبط‌شده وبینار «${this.submittedWebinarTitle}» لازم است نرم‌افزار اسپات پلیر (<a href="https://spotplayer.ir/">SpotPlayer</a>) را روی کامپیوتر یا تلفن همراه خود (ویندوز، مک، اندروید و یا آیفون) نصب کنید.
          <br><br>
          دقت داشته باشید اسپات‌پلیر حتما باید روی دستگاه شما نصب باشد. (ویدیو روی نسخه‌ی وب اسپات‌پلیر پخش نمی شود)
          <br><br>
          با وارد نمودن لایسنس زیر در برنامه به ویدیو دسترسی خواهید داشت:
          <br>
          </p>
          <code style="direction:ltr;text-align:left">
          ${this.selectedAttendees[0].licence}
          </code>
          <p style='text-align:right;direction:rtl;font-family:Tahoma;font-size:13px;'>
          <br>
          این لایسنس روی دو دستگاه (با سیستم عامل متفاوت) قابل استفاده است.<br>
          چنانچه دستگاه‌ خود را تغییر دهید یا به هر دلیلی لایسنس فعلی را از دست دهید، برای دریافت لایسنس جدید، نیاز به پرداخت هزینه است.<br><br>
          در صورت بروز مشکل یا عدم دسترسی به ویدیو، به 
          <a href="https://t.me/Ordmenism">آی‌دی ادمین</a>
          در تلگرام پیام دهید.
          <br><br>
          با سپاس، فاطمه گودرزی دستیار پادکست آدم‌های معمولی
          </p>
        `;
        break;
      case 'telegram':
        content = `${this.selectedAttendees[0].name} عزیز سلام؛<br><br>
برای دیدن ویدیوی ضبط‌شده وبینار «${this.submittedWebinarTitle}» لازم است نرم‌افزار اسپات پلیر (<a href="https://spotplayer.ir/">SpotPlayer</a>) را روی کامپیوتر یا تلفن همراه خود (ویندوز، مک، اندروید و یا آیفون) نصب کنید. دقت داشته باشید اسپات‌پلیر حتما باید روی دستگاه شما نصب باشد. (ویدیو روی نسخه‌ی وب اسپات‌پلیر پخش نمی شود) با وارد نمودن لایسنس زیر در برنامه به ویدیو دسترسی خواهید داشت:<br><br>
<code>${this.selectedAttendees[0].licence}</code><br>این لایسنس روی دو دستگاه (با سیستم عامل متفاوت) قابل استفاده است.<br>چنانچه دستگاه‌ خود را تغییر دهید یا به هر دلیلی لایسنس فعلی را از دست دهید، برای دریافت لایسنس جدید، نیاز به پرداخت هزینه است.<br><br>در صورت بروز مشکل یا عدم دسترسی به ویدیو، به <a href="https://t.me/Ordmenism">آی‌دی ادمین</a> در تلگرام پیام دهید.<br><br>با سپاس، فاطمه گودرزی دستیار پادکست آدم‌های معمولی`;
        break;
      case 'inviteEmail':
        content = `
          <p style='text-align:right;direction:rtl;font-family:Tahoma;font-size:13px;'>
          ${this.selectedAttendees[0].name} عزیز و گرامی درود؛<br><br>
          با سپاس از شما برای ثبت‌نام و شرکت در وبینار «${this.submittedWebinarTitle}»، لطفا برای حضور در وبینار به موارد زیر توجه داشته باشید:<br>
          ۱- از طریق این لینک به وبینار در <a href="${this.googleMeetLink}">گوگل میت</a> بپیوندید. شما قبلا به وبینار دعوت شده‌اید. توجه داشته باشید که باید با همان ایمیلی که در وبینار ثبت‌نام کردید وارد گوگل میت‌ شوید وگرنه اجازه ورود به شما داده نمی‌شود. <br>
          ۲- ترجیحا ده دقیقه قبل از شروع وبینار روی لینک باشید.<br>
          ۳- در زمان ورود لطفا ماکروفون‌تان را خاموش کنید. <br>
          ۴- در طول وبینار اگر پرسشی داشتید می‌تونید با بلند کردن دست و با تایید سخنران ماکروفن‌تان را باز کرده و سوال‌تان را مطرح کنید. یا اینکه می‌توانید سوال‌تان را در چت مطرح کنید تا سخنران به آن پاسخ دهد. اگر مایل بودید دوربین‌تان را هم می‌توانید روشن یا خاموش نگه دارید. <br>
          ۵- وبینار رکورد شده و پس از وبینار برای مشاهده دوباره در اختیارتان قرار خواهد گرفت. بنابراین برای حفظ حقوق معنوی پادکست آدمهای معمولی لطفا وبینار را رکورد نکنید. <br>
          ۶- برای هرگونه پرسش احتمالی و یا نیاز به هرگونه کمک در صورت مشکل داشتن در ورود به وبینار با پاسخ به همین ایمیل و یا با ارسال پیام به تلگرام ادمین پادکست تماس بگیرید. <br><br>
          مشتاقانه منتظر شروع وبینار و گفتگو با شما عزیزان هستیم.<br><br>
          با سپاس، فاطمه گودرزی دستیار پادکست آدم‌های معمولی
          </p>
        `;
        break;
      case 'inviteTelegram':
        content = `
          ${this.selectedAttendees[0].name} عزیز و گرامی درود؛<br><br>
          با سپاس از شما برای ثبت‌نام و شرکت در وبینار «${this.submittedWebinarTitle}»، لطفا برای حضور در وبینار به موارد زیر توجه داشته باشید:<br>
          ۱- از طریق این لینک به وبینار در <a href="${this.googleMeetLink}">گوگل میت</a> بپیوندید. شما قبلا به وبینار دعوت شده‌اید. توجه داشته باشید که باید با همان ایمیلی که در وبینار ثبت‌نام کردید وارد گوگل میت‌ شوید وگرنه اجازه ورود به شما داده نمی‌شود.<br>
          ۲- ترجیحا ده دقیقه قبل از شروع وبینار روی لینک باشید.<br>
          ۳- در زمان ورود لطفا ماکروفون‌تان را خاموش کنید.<br>
          ۴- در طول وبینار اگر پرسشی داشتید می‌تونید با بلند کردن دست و با تایید سخنران ماکروفن‌تان را باز کرده و سوال‌تان را مطرح کنید. یا اینکه می‌توانید سوال‌تان را در چت مطرح کنید تا سخنران به آن پاسخ دهد. اگر مایل بودید دوربین‌تان را هم می‌توانید روشن یا خاموش نگه دارید.<br>
          ۵- وبینار رکورد شده و پس از وبینار برای مشاهده دوباره در اختیارتان قرار خواهد گرفت. بنابراین برای حفظ حقوق معنوی پادکست آدمهای معمولی لطفا وبینار را رکورد نکنید.<br>
          ۶- برای هرگونه پرسش احتمالی و یا نیاز به هرگونه کمک در صورت مشکل داشتن در ورود به وبینار با پاسخ به همین ایمیل و یا با ارسال پیام به تلگرام ادمین پادکست تماس بگیرید.<br>
          مشتاقانه منتظر شروع وبینار و گفتگو با شما عزیزان هستیم.<br><br>
          با سپاس، فاطمه گودرزی دستیار پادکست آدم‌های معمولی
        `;
        break;
    }
    return content;
  },
  executeSend() {
    if (this.sendMethod) {
      this[this.sendMethod]();
      this.confirmationModalInstance.hide();
    }
  },
    resetFilters() {

      this.filters = {
        name: '',
        email: '',
        phone_number: '',
        chatID: '',
        product_id: '',
        product_name: '',
        amount: '',
      };
      this.filter = {
        nth_course: '',
        date_range: '',
        kind: '',
        location: ''
      };
      this.filterWebinars();
    }
  },
  mounted() {
    this.fetchWebinars();
    this.setDefaultMonth();
  }
};
</script>

<style scoped>

.container{
    width: 100% !important;
    max-width: 100% !important;
    font-size:12.5px!important;
  }

.markdown>table,.table {
    --tblr-table-color: inherit;
    --tblr-table-bg: transparent;
    --tblr-table-border-color: var(--tblr-border-color-translucent);
    --tblr-table-accent-bg: transparent;
    --tblr-table-striped-color: inherit;
    --tblr-table-active-color: inherit;
    --tblr-table-hover-color: inherit;
    background: #FFF;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--tblr-table-color);
    vertical-align: top;
    border-color: var(--tblr-table-border-color)
}
.accordion-row {
  background-color: #EEE; /* Optional: To visually distinguish the accordion rows */
}
.table-striped>tbody>tr.accordion-row:nth-of-type(even)>* {
    --tblr-table-accent-bg: #EEE;
    color: var(--tblr-table-striped-color);
}
.clickable {
  cursor: pointer;
}
</style>
