<template>
  <div>
    <div class="container mt-5">
      <div class="row">
        <!-- Left side: Transactions -->
        <div class="col-lg-9 col-md-9 col-12 mb-3">
          <!-- Search and filter section -->
          <div class="card p-3" style="border-radius:50px;padding-bottom:0;height:80px;">
            <div class="d-flex justify-content-between">
              <div>
                <input type="text" v-model="filter.search" @input="applyFilters" placeholder="جستجو: توضیحات" class="form-control form-search">
              </div>
              
              <div class="form-check mx-2" style="margin-top:12px;">
                <input type="checkbox" id="foreignTransactions" v-model="filter.foreign" @change="applyFilters" class="form-check-input">
                <label class="form-check-label" for="foreignTransactions">تراکنش‌های خارجی</label>
              </div>
              <div class="d-flex">
                <select v-model="pageSize" class="form-select mx-2" style="height:35px;margin-top:8px;" @change="changePageSize">
                  <option v-for="size in pageSizes" :key="size" :value="size">تعداد در هر صفحه: {{ size }}</option>
                </select>
                <button class="btn btn-primary" @click="showCostModal">ایجاد تراکنش</button>
              </div>
            </div>
          </div>

          <!-- Transactions table -->
          <div class="card mt-3" style="    max-height: 650px;">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <div v-else class="table-responsive">
              <table class="table table-striped" style="width: 100%; table-layout: auto; max-height: 650px; overflow-y: auto;">
                <thead>
                  <tr>
                    <th scope="col">ردیف</th>
                    <th scope="col">هزینه/درآمد</th>
                    <th scope="col">تاریخ</th>
                    <th scope="col">ساعت</th>
                    <th scope="col">نوع تراکنش</th>
                    <th scope="col">شرح تراکنش</th>
                    <th scope="col">درآمد</th>
                    <th scope="col">هزینه</th>
                    <th scope="col">بالانس</th>
                    <th scope="col">عملیات</th>

                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, i) in transactions" :key="transaction.id">
                    <td>{{ (currentPage - 1) * pageSize + i + 1 }}</td>
                    <td>{{ transaction.tr_type }}</td>
                    <td>{{ splitCreatedAt(transaction.created_at, 1) }}</td>
<td>{{ splitCreatedAt(transaction.created_at, 0) }}</td>

                    <td>{{ transaction.type }}</td>
                    <td>{{ transaction.description }}</td>
                    <td>{{ transaction.tr_type === 'درآمد' ? formatCurrency(transaction.amount).replace('ریال', '') : '' }}</td>
                    <td>{{ transaction.tr_type === 'هزینه' ? formatCurrency(transaction.amount).replace('ریال', '') : '' }}</td>
                    <td>{{ formatCurrency(getBalance(i)).replace('ریال', '') }}</td>
                    <td>
  <!-- Edit button with alternative SVG icon, shown only if source is 'profit' or 'cost' -->
  <button 
    v-if="transaction.source === 'profit' || transaction.source === 'cost'" 
    class="btn btn-warning btn-sm mx-1" 
    @click="showEditModal(transaction)" 
    aria-label="Edit">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
      <path d="M13.498.795l.149-.149a1.207 1.207 0 1 1 1.707 1.707l-.149.149a.5.5 0 0 1-.034.034l-10 10a.5.5 0 0 1-.168.11l-4 1a.5.5 0 0 1-.621-.621l1-4a.5.5 0 0 1 .11-.168l10-10a.5.5 0 0 1 .034-.034zm-1.94 1.757l-9.742 9.743-.691 2.764 2.764-.691 9.742-9.742-2.073-2.074z"/>
    </svg>
  </button>

  <!-- Delete button with SVG icon, shown only if source is 'profit' or 'cost' -->
  <button style="margin-right:4px;margin-top:3px;" 
    v-if="transaction.source === 'profit' || transaction.source === 'cost'" 
    class="btn btn-danger btn-sm" 
    @click="showDeleteModal(transaction)" 
    aria-label="Delete">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
      <path d="M5.5 5.5A.5.5 0 0 1 6 5h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5v-7zM4.118 4 4 4.059V13.5a1.5 1.5 0 0 0 1.5 1.5h5A1.5 1.5 0 0 0 12 13.5V4.059l-.118-.059H4.118zM2.5 3h11a.5.5 0 0 1 .5.5v.5H2v-.5a.5.5 0 0 1 .5-.5zM7.5 1.5A1.5 1.5 0 0 1 9 3h-2a1.5 1.5 0 0 1 1.5-1.5z"/>
    </svg>
  </button>
</td>

                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>


  
        <div class="col-lg-3 col-md-4 col-12">
          <!-- Add this table for statistics at the top of the table -->
          
<div class="card">

  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
      <li class="nav-item" role="presentation">
        <a href="#tabs-all-stats" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">تراکنش‌ها</a>
      </li>
      <li class="nav-item" role="presentation">
        <a href="#tabs-stats" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">تراکنش‌های ماه</a>
      </li>
      <li class="nav-item" role="presentation">
        <a href="#tabs-filters" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">فیلترها</a>
      </li>
    </ul>
  </div>
  <div class="card-body">
    <div class="tab-content">
      <div class="tab-pane fade active show" id="tabs-all-stats" role="tabpanel">
        <div class="mt-4">
          <!-- Stats -->


          <table class="table table-bordered">
  <thead>
    <tr>
      <th>ویژگی</th>
      <th class="centeredtext">مقدار</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>جمع هزینه‌ها</td>
      <td class="centeredtext">{{ formatCurrency(sumCosts).replace('ریال', '') }}</td>
    </tr>
    <tr>
      <td>جمع درآمدها</td>
      <td class="centeredtext">{{ formatCurrency(sumProfits).replace('ریال', '') }}</td>
    </tr>
    <tr>
      <td>نوع تراکنش با بیشترین درآمد</td>
      <td class="centeredtext">{{ highestProfitType }}</td>
    </tr>
    <tr>
      <td>نوع تراکنش با بیشترین هزینه</td>
      <td class="centeredtext">{{ highestCostType }}</td>
    </tr>
  </tbody>
</table>



        </div>
      </div>
      <div class="tab-pane fade" id="tabs-stats" role="tabpanel">
        <!-- Monthly Stats -->
        <div class="mt-4">

          <div class="mt-4">
  <div class="mb-3">
    <label for="monthSelect" class="form-label">انتخاب ماه:</label>
    <select v-model="selectedMonth" @change="updateMonthlyStats" id="monthSelect" class="form-select">
      <option value="01">فروردین</option>
      <option value="02">اردیبهشت</option>
      <option value="03">خرداد</option>
      <option value="04">تیر</option>
      <option value="05">مرداد</option>
      <option value="06">شهریور</option>
      <option value="07">مهر</option>
      <option value="08">آبان</option>
      <option value="09">آذر</option>
      <option value="10">دی</option>
      <option value="11">بهمن</option>
      <option value="12">اسفند</option>
    </select>
  </div>

  <table class="table table-bordered">
    <thead>
      <tr>
        <th>ویژگی</th>
        <th class="centeredtext">مقدار</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>جمع هزینه‌ها</td>
        <td class="centeredtext">{{ formatCurrency(monthlyStats.sumCosts).replace('ریال', '') }}</td>
      </tr>
      <tr>
        <td>جمع درآمدها</td>
        <td class="centeredtext">{{ formatCurrency(monthlyStats.sumProfits).replace('ریال', '') }}</td>
      </tr>
      <tr>
        <td>نوع تراکنش با بیشترین درآمد</td>
        <td class="centeredtext">{{ monthlyStats.highestProfitType }}</td>
      </tr>
      <tr>
        <td>نوع تراکنش با بیشترین هزینه</td>
        <td class="centeredtext">{{ monthlyStats.highestCostType }}</td>
      </tr>
    </tbody>
  </table>
</div>

          
        </div>
      </div>
      <div class="tab-pane fade" id="tabs-filters" role="tabpanel">
        <div class="mt-4">
          <!-- Filters -->
          <label for="product_name" class="form-label">نام محصول</label>
            <select v-model="filter.product_name" id="product_name" class="form-select mb-2">
              <option value="">همه</option>
             <option v-for="product in uniqueProducts" :key="product.title" :value="product.title">
              {{ product.title }}
            </option>

            <option key="مشاوره" value="مشاوره">مشاوره</option>
            </select>
            

            <label for="start_time" class="form-label">زمان شروع</label>
<date-picker type="datetime" v-model="filter.start_time" id="start_time" class="form-control mb-2"></date-picker>

<label for="end_time" class="form-label">زمان پایان</label>
<date-picker type="datetime" v-model="filter.end_time" id="end_time" class="form-control mb-2"></date-picker>



          <div class="d-flex justify-content-between mb-2">
            <button @click="resetFilters" class="btn btn-secondary">حذف فیلترها</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


      </div>
    </div>
  </div>

<!-- Delete Confirmation Modal -->
<div class="modal fade" id="deleteCostModal" tabindex="-1" role="dialog" aria-labelledby="deleteCostModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteCostModalLabel">حذف تراکنش</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>آیا مطمئن هستید که می‌خواهید این تراکنش را حذف کنید؟</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" @click="submitDeleteCost">حذف</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">لغو</button>
      </div>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<div class="modal fade" id="editCostModal" tabindex="-1" role="dialog" aria-labelledby="editCostModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editCostModalLabel">ویرایش تراکنش</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submitEditCost">
          <div class="row g-3">
            <!-- First Column -->
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editCostCurrency" class="form-label">واحد پول</label>
                <div class="d-flex align-items-center">
                  <input type="radio" id="editCurrencyToman" value="toman" v-model="editCost.currency" class="form-check-input">
                  <label for="editCurrencyToman" class="ms-2">تومانی</label>
                  <input type="radio" id="editCurrencyEuro" value="euro" v-model="editCost.currency" class="form-check-input ms-4">
                  <label for="editCurrencyEuro" class="ms-2">یورویی</label>
                </div>
              </div>

              <div class="mb-3">
                <label for="editCostSource" class="form-label">نوع تراکنش</label>
                <div class="d-flex align-items-center">
                  <input type="radio" id="editSourceCost" value="cost" v-model="editCost.source" class="form-check-input">
                  <label for="editSourceCost" class="ms-2">هزینه</label>
                  <input type="radio" id="editSourceProfit" value="profit" v-model="editCost.source" class="form-check-input ms-4">
                  <label for="editSourceProfit" class="ms-2">درآمد</label>
                </div>
              </div>
            </div>

            <!-- Second Column -->
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editCostType" class="form-label">نوع</label>
                <select id="editCostType" v-model="editCost.type" class="form-select" required>
                      <option value="پست">پست</option>
                      <option value="حقوق">حقوق</option>
                      <option value="فروش کتاب">فروش کتاب</option>
                      <option value="درآمد تبلیغ">درآمد تبلیغ</option>
                      <option value="درآمد کلاینت">درآمد کلاینت</option>
                      <option value="هزینه اپیزودها">هزینه اپیزودها</option>
                      <option value="هزینه جاری">هزینه جاری</option>
                      <option value="هزینه پروژه کلاینت">هزینه پروژه کلاینت</option>
                      <option value="واریزی تنخواه">واریزی تنخواه</option>
                      <option value="برگشت واریزی اشتباه">برگشت واریزی اشتباه</option>
                      <option value="برگشت تنخواه">برگشت تنخواه</option>
                      <option value="دریافتی بابت هزینه پروژه کلاینت">دریافتی بابت هزینه پروژه کلاینت</option>
                      <option value="حمایت مالی تومانی">حمایت مالی تومانی</option>
                      <option value="سهم سخنران وبینار">سهم سخنران وبینار</option>
                      <option value="پرداختی برای ard24">پرداختی برای ard24</option>
                      <option value="وبینار">وبینار</option>
                      <option value="تبلیغ پادکست">تبلیغ پادکست</option>
                      <option value="نیتانواستودیو">نیتانواستودیو</option>
                      <option value="برگشت هزینه وبینار">برگشت هزینه وبینار</option>
                      <option value="هزینه وبسایت">هزینه وبسایت</option>
                      <option value="محتوا برای آرد۲۴">محتوا برای آرد۲۴</option>
                      <option value="مارکتینگ برای آرد۲۴">مارکتینگ برای آرد۲۴</option>
                      <option value="درآمد یوتیوب">درآمد یوتیوب</option>
                      <option value="درآمد تومانی مشاوره">درآمد تومانی مشاوره</option>
                      <option value="درامد ارزی مشاوره">درامد ارزی مشاوره</option>
                      <option value="پروژه سمیه">پروژه سمیه</option>
                      <option value="برگشت هزینه وبینار">برگشت هزینه وبینار</option>
                      <option value="محصولات دیجیتال">محصولات دیجیتال</option>
                      <option value="برگشت هزینه جاری">برگشت هزینه جاری</option>
                  <!-- Add other options as needed -->
                </select>
              </div>

              <div class="mb-3">
                <label for="editCostDescription" class="form-label">شرح</label>
                <input type="text" id="editCostDescription" v-model="editCost.description" class="form-control" required>
              </div>
            </div>

            <!-- Third Column -->
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editCostAmount" class="form-label">مبلغ</label>
                <input type="number" id="editCostAmount" v-model="editCost.amount" class="form-control" required>
              </div>
            </div>


          </div>

          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">ثبت تغییرات</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">لغو</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>




<!-- Cost Modal -->
<div class="modal fade" id="addCostModal" tabindex="-1" role="dialog" aria-labelledby="addCostModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addCostModalLabel">ایجاد تراکنش جدید</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submitCost">
          <div class="row g-3">
            <!-- Left Column for description inputs -->
            <div class="col-md-6">
              <!-- Description selection method -->
              <div class="mb-3">
                <label class="form-label">روش وارد کردن شرح تراکنش</label>
                <div class="d-flex align-items-center">
                  <input type="radio" id="manualDescription" value="manual" v-model="descriptionMethod" class="form-check-input">
                  <label for="manualDescription" class="ms-2">وارد کردن دستی</label>
                  <input type="radio" id="productDescription" value="product" v-model="descriptionMethod" class="form-check-input ms-4">
                  <label for="productDescription" class="ms-2">انتخاب محصول و وارد کردن نام شرکت‌کننده</label>
                </div>
              </div>

              <!-- Conditional input fields based on the selected method -->
              <div v-if="descriptionMethod === 'manual'" class="mb-3">
                <label for="manualDescriptionInput" class="form-label">شرح</label>
                <input type="text" id="manualDescriptionInput" v-model="newCost.description" class="form-control" placeholder="شرح تراکنش">
              </div>

              <div v-if="descriptionMethod === 'product'" class="mb-3">
                <label for="productSelect" class="form-label">انتخاب محصول</label>
                <select id="productSelect" v-model="selectedProduct" class="form-select">
                  <option value="">انتخاب محصول</option>
                  <option v-for="product in uniqueProducts" :key="product.title" :value="product.title">{{ product.title }}</option>
                </select>
              </div>

              <div v-if="descriptionMethod === 'product'" class="mb-3">
                <label for="participantName" class="form-label">نام شرکت‌کننده</label>
                <input type="text" id="participantName" v-model="participantName" class="form-control" placeholder="نام شرکت‌کننده">
              </div>
              
              <div class="mb-3">
                <label for="costType" class="form-label">نوع</label>
                    <select id="costType" v-model="newCost.type" class="form-select" required>
                      <option value="پست">پست</option>
                      <option value="حقوق">حقوق</option>
                      <option value="فروش کتاب">فروش کتاب</option>
                      <option value="درآمد تبلیغ">درآمد تبلیغ</option>
                      <option value="درآمد کلاینت">درآمد کلاینت</option>
                      <option value="هزینه اپیزودها">هزینه اپیزودها</option>
                      <option value="هزینه جاری">هزینه جاری</option>
                      <option value="هزینه پروژه کلاینت">هزینه پروژه کلاینت</option>
                      <option value="واریزی تنخواه">واریزی تنخواه</option>
                      <option value="برگشت واریزی اشتباه">برگشت واریزی اشتباه</option>
                      <option value="برگشت تنخواه">برگشت تنخواه</option>
                      <option value="دریافتی بابت هزینه پروژه کلاینت">دریافتی بابت هزینه پروژه کلاینت</option>
                      <option value="حمایت مالی تومانی">حمایت مالی تومانی</option>
                      <option value="سهم سخنران وبینار">سهم سخنران وبینار</option>
                      <option value="پرداختی برای ard24">پرداختی برای ard24</option>
                      <option value="وبینار">وبینار</option>
                      <option value="تبلیغ پادکست">تبلیغ پادکست</option>
                      <option value="نیتانواستودیو">نیتانواستودیو</option>
                      <option value="برگشت هزینه وبینار">برگشت هزینه وبینار</option>
                      <option value="هزینه وبسایت">هزینه وبسایت</option>
                      <option value="محتوا برای آرد۲۴">محتوا برای آرد۲۴</option>
                      <option value="مارکتینگ برای آرد۲۴">مارکتینگ برای آرد۲۴</option>
                      <option value="درآمد یوتیوب">درآمد یوتیوب</option>
                      <option value="درآمد تومانی مشاوره">درآمد تومانی مشاوره</option>
                      <option value="درامد ارزی مشاوره">درامد ارزی مشاوره</option>
                      <option value="پروژه سمیه">پروژه سمیه</option>
                      <option value="برگشت هزینه وبینار">برگشت هزینه وبینار</option>
                      <option value="محصولات دیجیتال">محصولات دیجیتال</option>
                      <option value="برگشت هزینه جاری">برگشت هزینه جاری</option>
                    </select>
              </div>

            </div>

            <!-- Right Column for currency and transaction type -->
            <div class="col-md-6">
              <!-- Currency selection -->
              <div class="mb-3">
                <label for="costCurrency" class="form-label">واحد پول</label>
                <div class="d-flex align-items-center">
                  <input type="radio" id="currencyToman" value="toman" v-model="newCost.currency" class="form-check-input">
                  <label for="currencyToman" class="ms-2">تومانی</label>
                  <input type="radio" id="currencyEuro" value="euro" v-model="newCost.currency" class="form-check-input ms-4">
                  <label for="currencyEuro" class="ms-2">یورویی</label>
                </div>
              </div>

              <!-- Transaction source (cost or profit) -->
              <div class="mb-3">
                <label class="form-label">نوع تراکنش</label>
                <div class="d-flex align-items-center">
                  <input type="radio" id="sourceCost" value="cost" v-model="newCost.source" class="form-check-input">
                  <label for="sourceCost" class="ms-2">هزینه</label>
                  <input type="radio" id="sourceProfit" value="profit" v-model="newCost.source" class="form-check-input ms-4">
                  <label for="sourceProfit" class="ms-2">درآمد</label>
                </div>
              </div>

              <!-- Amount input -->
              <div class="mb-3">
                <label for="costAmount" class="form-label">مبلغ</label>
                <input type="number" id="costAmount" v-model="newCost.amount" class="form-control" required>
              </div>

              <!-- Optional Date input -->
              <div class="mb-3">
                <label for="costDate" class="form-label">تاریخ (اختیاری)</label>
                <input type="datetime-local" id="costDate" v-model="newCost.created_at" class="form-control">
              </div>
            </div>
          </div>
          
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">ثبت</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">لغو</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

import jalaali from 'jalaali-js';
import DatePicker from 'vue3-persian-datetime-picker';

export default {
  components: {
    DatePicker,
  },
  data() {
      return {
        transactions: [], // List of all transactions

      selectedMonth: '', // Selected month for the monthly stats

      descriptionMethod: 'manual', // 'manual' or 'product'
      selectedProduct: '',
      participantName: '',
      monthlyStats: {
        sumCosts: 0,
        sumProfits: 0,
        highestProfitType: '',
        highestCostType: '',
      },
        products: [], // List of all products/webinars
        filteredTransactions: [], // Filtered transactions based on search and filters
        pageSize: 10, // Number of items per page
        currentPage: 1, // Current page for pagination
        pageSizes: [10, 25, 50, 100], // Options for page size
        filter: {
          search: '', // Search term for filtering
          foreign: false, // Checkbox filter for foreign transactions (euro)
          product_name: '', // Filter by product/webinar name
          
        start_time: '',
        end_time: '',
        },
        newCost: {
          type: '', // Type of transaction (e.g., "پست", "حقوق")
          description: '', // Description of the transaction
          amount: null, // Amount of the transaction
          created_at: '', // Optional: Date of transaction
          currency: 'toman', // Currency: either "toman" or "euro"
          source: 'cost', // Source: either "cost" or "profit"
        },
        editCost: {
          id: null, // ID of the transaction to be edited
          type: '', // Type of transaction for editing
          description: '', // Description of the transaction for editing
          amount: null, // Amount of the transaction for editing
          created_at: '', // Optional: Date of transaction for editing
          currency: 'toman', // Currency for editing
          source: 'cost', // Source for editing
        },
        deleteCostId: null, // ID of the transaction to be deleted
        error: null, // To store error messages
      };
    },
    watch: {
  'filter.product_name': 'applyFilters',
  'filter.foreign': 'applyFilters',
  'filter.search': 'applyFilters',
  'filter.start_time': 'applyFilters',
  'filter.end_time': 'applyFilters'
},

  computed: {
    sumCosts() {
    return this.filteredTransactions
      .filter(transaction => transaction.tr_type === 'هزینه')
      .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);
  },

  // Updated sumProfits to consider the foreign transactions filter
  sumProfits() {
    return this.filteredTransactions
      .filter(transaction => transaction.tr_type === 'درآمد')
      .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);
  },

  // Updated highestProfitType to consider the foreign transactions filter
  highestProfitType() {
    const profits = this.filteredTransactions.filter(transaction => transaction.tr_type === 'درآمد');
    const profitSumsByType = {};

    profits.forEach(transaction => {
      if (!profitSumsByType[transaction.type]) {
        profitSumsByType[transaction.type] = 0;
      }
      profitSumsByType[transaction.type] += parseFloat(transaction.amount);
    });

    let maxType = '';
    let maxAmount = 0;

    Object.entries(profitSumsByType).forEach(([type, amount]) => {
      if (amount > maxAmount) {
        maxAmount = amount;
        maxType = type;
      }
    });

    return maxType;
  },

  // Updated highestCostType to consider the foreign transactions filter
  highestCostType() {
    const costs = this.filteredTransactions.filter(transaction => transaction.tr_type === 'هزینه');
    const costSumsByType = {};

    costs.forEach(transaction => {
      if (!costSumsByType[transaction.type]) {
        costSumsByType[transaction.type] = 0;
      }
      costSumsByType[transaction.type] += parseFloat(transaction.amount);
    });

    let maxType = '';
    let maxAmount = 0;

    Object.entries(costSumsByType).forEach(([type, amount]) => {
      if (amount > maxAmount) {
        maxAmount = amount;
        maxType = type;
      }
    });

    return maxType;
  },
    
    uniqueProducts() {
    const titles = new Set();
    return this.products.filter(product => {
      if (!titles.has(product.title)) {
        titles.add(product.title);
        return true;
      }
      return false;
    });
  },
    totalPages() {
      return Math.ceil(this.filteredTransactions.length / this.pageSize);
    },
    paginatedPages() {
      const total = this.totalPages;
      const current = this.currentPage;
      const delta = 2;
      const range = [];
      const rangeWithDots = [];
      let l;

      range.push(1);
      for (let i = current - delta; i <= current + delta; i++) {
        if (i >= 2 && i <= total - 1) {
          range.push(i);
        }
      }
      range.push(total);

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredTransactions.slice(start, end);
    },
  },

  created() {
    this.fetchProducts(); // Fetch the list of products on component creation
    this.setDefaultMonth();
    this.updateMonthlyStats();
  },
  methods: {

    setDefaultMonth() {
      const now = new Date();
      const { jm } = jalaali.toJalaali(now);
      const month = jm.toString().padStart(2, '0');
      this.selectedMonth = month;
      this.updateMonthlyStats();
    },
    
    updateMonthlyStats() {
  const monthTransactions = this.transactions.filter(transaction => {
    const jalaaliDate = transaction.created_at.split(' - ')[1];
    const jm = jalaaliDate.split('-')[1];

    // Apply the currency filter if foreign transactions filter is enabled
    if (this.filter.foreign && transaction.currency !== 'euro') {
      return false;
    } else if (!this.filter.foreign && transaction.currency !== 'toman') {
      return false;
    }

    return parseInt(jm, 10) === parseInt(this.selectedMonth, 10);
  });

  // Sum of costs for the filtered month transactions
  this.monthlyStats.sumCosts = monthTransactions
    .filter(transaction => transaction.tr_type === 'هزینه')
    .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);

  // Sum of profits for the filtered month transactions
  this.monthlyStats.sumProfits = monthTransactions
    .filter(transaction => transaction.tr_type === 'درآمد')
    .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);

  // Find the highest profit type for the filtered month transactions
  const profitSumsByType = {};
  monthTransactions
    .filter(transaction => transaction.tr_type === 'درآمد')
    .forEach(transaction => {
      if (!profitSumsByType[transaction.type]) {
        profitSumsByType[transaction.type] = 0;
      }
      profitSumsByType[transaction.type] += parseFloat(transaction.amount);
    });

  this.monthlyStats.highestProfitType = Object.keys(profitSumsByType).reduce(
    (a, b) => (profitSumsByType[a] > profitSumsByType[b] ? a : b),
    ''
  );

  // Find the highest cost type for the filtered month transactions
  const costSumsByType = {};
  monthTransactions
    .filter(transaction => transaction.tr_type === 'هزینه')
    .forEach(transaction => {
      if (!costSumsByType[transaction.type]) {
        costSumsByType[transaction.type] = 0;
      }
      costSumsByType[transaction.type] += parseFloat(transaction.amount);
    });

  this.monthlyStats.highestCostType = Object.keys(costSumsByType).reduce(
    (a, b) => (costSumsByType[a] > costSumsByType[b] ? a : b),
    ''
  );
},
  splitCreatedAt(dateString, i) {
    if (dateString) {
      return dateString.split(' - ')[i];
    }
    return '';
  },
  showEditModal(transaction) {
    this.editCost = { ...transaction }; // Clone the transaction to avoid direct binding
    const modal = new Modal(document.getElementById('editCostModal'));
    modal.show();
  },
  showDeleteModal(transaction) {
    this.deleteCostId = transaction.id;
    const modal = new Modal(document.getElementById('deleteCostModal'));
    modal.show();
  },
  
    submitEditCost() {
      const token = localStorage.getItem('jwt');
      this.editCost.action = 'edit';  // Add action parameter for edit

      axios
        .post('https://dashboard.ordmenpodcast.com/v/delete_edit_cost_profit.php', this.editCost, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          if (response.data.success) {
            alert('تراکنش با موفقیت ویرایش شد');
            this.fetchTransactions();
            const modal = Modal.getInstance(document.getElementById('editCostModal'));
            modal.hide();
          } else {
            alert('خطا در ویرایش تراکنش');
          }
        })
        .catch(error => {
          this.error = 'Error: ' + error;
        });
    },

    submitDeleteCost() {
      const token = localStorage.getItem('jwt');
      
      // Create delete request with action parameter
      axios
        .post('https://dashboard.ordmenpodcast.com/v/delete_edit_cost_profit.php', {
          action: 'delete',   // Add action parameter for delete
          id: this.deleteCostId,
          source: this.transactions.find(t => t.id === this.deleteCostId).source,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          if (response.data.success) {
            alert('تراکنش با موفقیت حذف شد');
            this.fetchTransactions();
            const modal = Modal.getInstance(document.getElementById('deleteCostModal'));
            modal.hide();
          } else {
            alert('خطا در حذف تراکنش');
          }
        })
        .catch(error => {
          this.error = 'Error: ' + error;
        });
    },

    fetchTransactions() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/get_accounting_data.php', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.transactions = response.data;
          this.applyFilters();
        this.updateMonthlyStats(); // Ensure stats are updated after transactions are loaded
        })
        .catch((error) => {
          this.error = error.response
            ? error.response.data.error
            : 'No response from server. Please try again later.';
        });
    },
    
    fetchProducts() {
      const token = localStorage.getItem('jwt');
      axios.get('https://dashboard.ordmenpodcast.com/v/list_webinars.php', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        this.products = response.data.webinars;
      })
      .catch(error => {
        console.error(error);
      });
    },
    
    applyFilters() {
  this.filteredTransactions = this.transactions.filter((transaction) => {
    const search = this.filter.search.toLowerCase();

    // Search filter
    if (search && !transaction.description.toLowerCase().includes(search)) {
      return false;
    }

    // Product name filter
    if (this.filter.product_name && !transaction.description.includes(this.filter.product_name)) {
      return false;
    }

    // Currency filter
    if (this.filter.foreign) {
      if (transaction.currency !== 'euro') return false;
    } else {
      if (transaction.currency !== 'toman') return false;
    }

    // Time period filter
    const transactionDate = new Date(transaction.created_at.replace(/-/g, '/')); // Ensure consistent format

    const startTime = this.filter.start_time ? new Date(this.filter.start_time) : null;
    const endTime = this.filter.end_time ? new Date(this.filter.end_time) : null;

    if (startTime && transactionDate < startTime) return false;
    if (endTime && transactionDate > endTime) return false;

    return true;
  });

  // Sort transactions from oldest to newest
  this.filteredTransactions.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  // Update monthly stats when filters are applied
  this.updateMonthlyStats();
},
  
  resetFilters() {
      this.filter.product_name = '';
      this.filter.start_time = '';
      this.filter.end_time = '';
      this.fetchTransactions();
    },

    getBalance(index) {
      let balance = 0;
      const globalIndex = index + (this.currentPage - 1) * this.pageSize; // Calculate the global index
      
      // Iterate backward from the last transaction towards the current one
      for (let i = this.filteredTransactions.length - 1; i >= globalIndex; i--) {
        if (this.filteredTransactions[i].tr_type === 'درآمد') {
          balance += parseFloat(this.filteredTransactions[i].amount);
        } else if (this.filteredTransactions[i].tr_type === 'هزینه') {
          balance -= parseFloat(this.filteredTransactions[i].amount);
        }
      }
      return balance;
    },

    formatCurrency(amount) {
      return new Intl.NumberFormat('fa-IR', {
        style: 'currency',
        currency: 'IRR',
      }).format(amount);
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('fa-IR');
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    changePageSize() {
      this.currentPage = 1;
      this.applyFilters();
    },

    showCostModal() {
      const modal = new Modal(document.getElementById('addCostModal'));
      modal.show();
    },
    
    
    submitCost() {
      if (this.descriptionMethod === 'product' && this.selectedProduct && this.participantName) {
        // Concatenate product name and participant name
        this.newCost.description = `${this.selectedProduct} - ${this.participantName}`;
      }
      
      const token = localStorage.getItem('jwt');
      axios
        .post('https://dashboard.ordmenpodcast.com/v/add_cost.php', this.newCost, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          if (response.data.success) {
            alert('تراکنش جدید با موفقیت اضافه شد');
            this.resetForm();
            const modal = Modal.getInstance(document.getElementById('addCostModal'));
            modal.hide();
            this.fetchTransactions(); // Fetch updated data and refresh the table
          } else {
            alert('خطایی رخ داده است: ' + response.data.error);
          }
        })
        .catch((error) => {
          this.error = 'Error: ' + error;
        });
    },
    resetForm() {
      this.newCost = {
        type: '',
        description: '',
        amount: null,
        created_at: '' // Reset to empty
      };
      this.selectedProduct = '';
      this.participantName = '';
      this.descriptionMethod = 'manual';
    },
    downloadCSV() {
      const csvData = this.filteredTransactions.map((transaction) => ({
        نوع: transaction.type,
        توضیحات: transaction.description,
        مبلغ: transaction.amount,
        تاریخ: this.formatDate(transaction.created_at),
      }));

      const csvContent =
        'data:text/csv;charset=utf-8,' +
        ['نوع,توضیحات,مبلغ,تاریخ']
          .concat(
            csvData.map((row) =>
              Object.values(row)
                .map((value) => `"${value}"`)
                .join(',')
            )
          )
          .join('\n');

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'transactions.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted() {
    this.fetchTransactions();
  },
};
</script>



<style scoped>

.container{
    width: 100% !important;
    max-width: 100% !important;
    font-size:12.5px!important;
  }


.pagination-container {
  margin-top: 15px;
}

.page-item {
  padding: 0 5px;
}
</style>
