<template>
  <div class="container mt-4">
    <h2>پیام‌ها</h2>
    <div v-if="showMessagesTable">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>آیدی</th>
            <th>ایمیل</th>
            <th>عنوان</th>
            <th>نویسنده</th>
            <th>تاریخ</th>
            <th>زمان</th>
            <th>اطلاعات بیشتر</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="message in messages" :key="message.id" :class="{ 'done-message': message.done == 1 }">
            <td>{{ message.id }}</td>
            <td>{{ message.email }}</td>
            <td>{{ message.title }}</td>
            <td>{{ message.author }}</td>
            <td>{{ formatShamsiDate(message.created_at).date }}</td>
            <td>{{ formatShamsiDate(message.created_at).time }}</td>
            <td>
              <button class="btn btn-info btn-sm" @click="viewMessageHistory(message)">
                دیدن
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="showMessageHistory" style="margin-top:-15px;">
      <button class="btn btn-secondary mt-3" @click="goBackToMessages">بازگشت به جدول پیام‌ها</button><br><br>
      <h3>تاریخچه پیام</h3>
      <div v-for="(message, index) in messageHistory" :key="message.id" class="card my-2">
        <div class="card-body">
          <h5 class="card-title">پیام {{ index + 1 }}</h5>
          <p class="card-text"><strong>نویسنده:</strong> {{ message.author }}</p>
          <p class="card-text"><strong>موضوع:</strong> {{ message.subject }}</p>
          <p class="card-text"><strong>تاریخ:</strong> {{ formatShamsiDate(message.created_at).date }}</p>
          <p class="card-text"><strong>زمان:</strong> {{ formatShamsiDate(message.created_at).time }}</p>
          <p class="card-text"><strong>متن:</strong> {{ message.content }}</p>
<!-- Button container -->
<div style="float:right;width:100%; margin-top: 0px;">

<!-- Reply button -->
<button v-if="message.author !== 'ادمین' && (index === 0 || index === messageHistory.length - 1)"
  class="btn btn-info btn-sm" style="margin-right:5px;margin-bottom:5px;" 
  @click="toggleReplyBox(message)">
  پاسخ
</button>

<!-- Add button for Kassar (کسر از کیف پول) -->
<button v-if="index == 0 && message.title.includes('نقد کردن کیف پول') && message.done != 1"
  class="btn btn-warning btn-sm" style="margin-right:5px;margin-bottom:5px;" 
  @click="confirmWalletUpdate(message)">
  کسر از کیف پول
</button>

<!-- Completion button -->
<button v-if="index == 0 && message.done != 1"
  class="btn btn-success btn-sm" style="margin-right:5px;margin-bottom:5px;" 
  @click="confirmCompletion(message)">
  تکمیل
</button>

</div>


          <!-- CKEditor for reply -->
          <div v-if="message.showReplyBox" class="mt-2" style="margin-right:5px;">
            <ckeditor :editor="editor" v-model="message.replyText" :config="editorConfig"></ckeditor>
            <button class="btn btn-info btn-sm" @click="sendReply(message)">ارسال پاسخ</button>
            <!-- Cancel Reply Button -->
<button v-if="message.showReplyBox" class="btn btn-secondary btn-sm ml-2" @click="cancelReply(message)">
  انصراف از پاسخ دادن
</button>

          </div>
        </div>
      </div>
    </div>

    <!-- Subtract Amount Modal -->
    <div
      class="modal fade"
      id="subtractAmountModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="subtractAmountModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="subtractAmountModalLabel">کسر از کیف پول</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>مقدار کنونی کیف پول ریال: {{ iranWalletValue }}</p>
            <p>مقدار کنونی کیف پول یورو: {{ euroWalletValue }}</p>
            <div class="form-group">
              <label for="walletType">انتخاب کیف پول:</label>
              <select v-model="selectedWallet" class="form-control">
                <option value="IranWallet">کیف پول ریال</option>
                <option value="EuroWallet">کیف پول یورو</option>
              </select>
            </div>
            <div class="form-group mt-3">
              <label for="subtractAmount">مبلغ:</label>
              <input type="number" v-model="subtractAmount" class="form-control" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              انصراف
            </button>
            <button type="button" class="btn btn-info" @click="showConfirmModal">
              ادامه
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirm Wallet Update Modal -->
    <div
      class="modal fade"
      id="confirmWalletUpdateModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmWalletUpdateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmWalletUpdateModalLabel">تایید کسر از کیف پول</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>کیف پول: {{ selectedWallet === 'IranWallet' ? 'کیف پول ریال' : 'کیف پول یورو' }}</p>
            <p>مقدار کنونی: {{ selectedWallet === 'IranWallet' ? iranWalletValue : euroWalletValue }}</p>
            <p>مقدار جدید: {{ updatedWalletValue }}</p>
            <p>آیا مطمئن هستید که می‌خواهید این مقدار را کسر کنید؟</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              انصراف
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click="updateWallet"
            >
              تایید
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- Toast -->
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="copyToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">عملیات</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>
    <!-- Confirm Completion Modal -->
    <div
      class="modal fade"
      id="confirmCompletionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmCompletionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmCompletionModalLabel">تایید تکمیل</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>آیا مطمئن هستید که می‌خواهید این پیام را تکمیل کنید؟</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              انصراف
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="completeMessage"
            >
              تکمیل
            </button>
          </div>
        </div>
      </div>
    </div>
    <br><br><br><br>
  </div>
</template>

<script>
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal, Toast } from 'bootstrap';
import { htmlToText } from 'html-to-text';

export default {
  data() {
    return {
      messages: [],
      messageHistory: [],
      showMessagesTable: true,
      showMessageHistory: false,
      selectedWallet: '',
      subtractAmount: 0,
      iranWalletValue: 0,
      euroWalletValue: 0,
      updatedWalletValue: 0,
      selectedMessage: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'ar',
        contentsLangDirection: 'rtl',
      },
    };
  },
  methods: {
    
    toggleReplyBox(message) {
      message.showReplyBox = !message.showReplyBox;
    },
    fetchMessages() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_messages.php', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.messages = response.data.filter(
            (message) => message.primary_message === '1'
          );
        })
        .catch((error) => {
          console.error('Error fetching messages:', error);
        });
    },
    formatShamsiDate(dateTimeString) {
      const [time, date] = dateTimeString.split(' - ');
      return { date, time };
    },
    viewMessageHistory(primaryMessage) {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_messages.php', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const allMessages = response.data;

          let history = [primaryMessage];
          const collectReplies = (messageId) => {
            const replies = allMessages.filter(
              (msg) => msg.replied_to_message_id === messageId
            );
            replies.forEach((reply) => {
              reply.showReplyBox = false;
              reply.replyText = '';
              history.push(reply);
              collectReplies(reply.message_id);
            });
          };

          collectReplies(primaryMessage.message_id);
          this.messageHistory = history;
          this.showMessagesTable = false;
          this.showMessageHistory = true;
        })
        .catch((error) => {
          console.error('Error fetching message history:', error);
        });
    },
    cancelReply(message) {
  message.showReplyBox = false;
},
    goBackToMessages() {
      this.showMessageHistory = false;
      this.showMessagesTable = true;
    },
    confirmWalletUpdate(message) {
      this.selectedMessage = message;
      const token = localStorage.getItem('jwt');

      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/get_dashboard.php',
          {
            chatId: message.chatID,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.iranWalletValue = parseInt(response.data['IranWallet'], 10);
          this.euroWalletValue = parseInt(response.data['EuroWallet'], 10);

          const modalElement = document.getElementById('subtractAmountModal');
          this.confirmWalletUpdateModalInstance = new Modal(modalElement);
          this.confirmWalletUpdateModalInstance.show();
        })
        .catch((error) => {
          console.error('Error fetching wallet data:', error);
        });
    },
    showConfirmModal() {
      const selectedWalletValue =
        this.selectedWallet === 'IranWallet'
          ? this.iranWalletValue
          : this.euroWalletValue;

      this.updatedWalletValue = selectedWalletValue - this.subtractAmount;

      const subtractModalElement = document.getElementById('subtractAmountModal');
      const subtractModalInstance = Modal.getInstance(subtractModalElement);
      subtractModalInstance.hide();

      const modalElement = document.getElementById('confirmWalletUpdateModal');
      this.confirmWalletUpdateModalInstance = new Modal(modalElement);
      this.confirmWalletUpdateModalInstance.show();
    },
    updateWallet() {
      const token = localStorage.getItem('jwt');
      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/change_dashboard.php',
          {
            chatId: this.selectedMessage.chatID,
            column: this.selectedWallet,
            value: this.updatedWalletValue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            this.confirmWalletUpdateModalInstance.hide();
            this.fetchMessages();
            this.showToast(
              `مقدار کسر شده از ${
                this.selectedWallet === 'IranWallet' ? 'کیف پول ریال' : 'کیف پول یورو'
              } با موفقیت انجام شد.`
            );
          } else {
            console.error('Error updating wallet:', response.data.error);
          }
        })
        .catch((error) => {
          console.error('Error updating wallet:', error);
        });
    },
    confirmCompletion(message) {
      this.selectedMessage = message;
      const modalElement = document.getElementById('confirmCompletionModal');
      this.confirmModalInstance = new Modal(modalElement);
      this.confirmModalInstance.show();
    },
    async completeMessage() {
      const token = localStorage.getItem('jwt');

      try {
        console.log(this.selectedMessage.id);
        // Mark the message as done
        const messageResponse = await axios.post(
          'https://dashboard.ordmenpodcast.com/v/change_message.php',
          {
            id: this.selectedMessage.id,
            column: 'done',
            value: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (messageResponse.data.success) {
          this.selectedMessage.done = 1;
          this.confirmModalInstance.hide();
          this.fetchMessages(); // Fetch the updated messages
          this.showToast('پیام با موفقیت تکمیل شد.');
        } else {
          console.error('Error updating message:', messageResponse.data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    
    sendReply(message) {
  const token = localStorage.getItem('jwt');
  const replyText = message.replyText;
  const telegramMessage = `
    ${htmlToText(replyText, {
      wordwrap: 130,
      uppercaseHeadings: false,
      singleNewLineParagraphs: true,
      tags: {
        a: { format: 'inline' },
        p: { format: 'block' }
      }
    })}
  `;

  // Always use the email of the second user (non-admin)
  const email = message.email;

  // Find the primary message (first message in the history)
  const primaryMessage = this.messageHistory.find(msg => msg.primary_message === "1");

  axios
    .post(
      'https://dashboard.ordmenpodcast.com/v/send_telegram_using_email_message.php',
      {
        email: email,
        text: telegramMessage,
        replied_to_message_id: message.message_id, // Replying to the message
        primary_message: 0 // Indicates that this is a reply, not a primary message
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => {
      console.log('Reply sent successfully:', response.data);

      // Reset the reply box
      message.replyText = '';
      message.showReplyBox = false;

      // Fetch the updated history based on the primary message
      this.fetchUpdatedMessageHistory(primaryMessage.message_id);
    })
    .catch((error) => {
      console.error('Error sending reply:', error);
    });
},
fetchUpdatedMessageHistory(primaryMessageId) {
  const token = localStorage.getItem('jwt');
  axios
    .get('https://dashboard.ordmenpodcast.com/v/list_messages.php', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const allMessages = response.data;

      // Start the history with the primary message
      const primaryMessage = allMessages.find(msg => msg.message_id === primaryMessageId);
      let history = [primaryMessage];

      const collectReplies = (msgId) => {
        const replies = allMessages.filter(msg => msg.replied_to_message_id === msgId);
        replies.sort((a, b) => a.message_id - b.message_id);
        replies.forEach((reply) => {
          history.push(reply);
          collectReplies(reply.message_id); // Recursively collect all replies
        });
      };

      collectReplies(primaryMessage.message_id);
      this.messageHistory = history; // Update the displayed history
    })
    .catch((error) => {
      console.error('Error fetching updated message history:', error);
    });
},
    
    showToast(message) {
      this.toastMessage = message;
      const toastElement = document.getElementById('copyToast');
      const toast = new Toast(toastElement);
      toast.show();
    },
  },
  mounted() {
    this.fetchMessages();
  },
};
</script>

<style scoped>
.container {
  width: 100% !important;
  max-width: 100% !important;
  font-size: 12.5px !important;
  margin-top: 20px;
}
.table {
  margin-top: 20px;
}

.card {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}

.btn-info {
  color: white;
}

.btn-success{
  margin-top: 0;
    margin-bottom: 0;
}

.done-message {
  background-color: #f0fff0 !important;
}

</style>
